<template>
  <section class="our-approach">
    <div class="container">
      <div
        ref="content"
        class="approach-content">
        <div
          class="eyebrow"
          v-html="data.approach_tagline"></div>
        <div
          class="headline h3 font-med"
          v-html="data.approach_headline"></div>
        <div
          class="text editor-formatting"
          v-html="data.approach_text"></div>
      </div>
    </div>
    <div
      ref="circle"
      class="approach-circle">
      <img
        src="@/assets/img/ac-circle-outer.svg"
        alt="" />
      <img
        ref="innerCircle"
        src="@/assets/img/ac-circle-inner.png"
        alt=""
        class="c-inner" />
      <div class="ac-btns">
        <div
          v-for="(item, index) in data.approach_items"
          :key="index"
          class="title"
          :class="{ active: activeItem == index }"
          @click="animateCircle(index)">
          <div class="ac-btn">
            <div v-html="item.approach_item_title"></div>
          </div>
          <div
            class="title-headline h1"
            v-html="item.approach_item_title"></div>
        </div>
      </div>
    </div>
    <div
      class="approach-info"
      :class="{ active: isActive }">
      <div
        ref="infoContainer"
        class="approach-info-container">
        <div
          ref="close"
          class="close"
          @click="reverseCircle">
          <img
            src="@/assets/img/button-close.svg"
            alt="Close Button" />
        </div>
        <template v-for="(item, index) in data.approach_items">
          <div
            v-if="activeItem == index"
            :key="index"
            class="approach-item">
            <img
              v-lazy="item.approach_item_image.url"
              :alt="item.approach_item_image.alt" />
            <div
              class="text editor-formatting font-smaller"
              v-html="item.approach_item_text"></div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "ApproachComponent",
    "CLASSNAME": "our-approach",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { ref, onBeforeUnmount, onMounted } from 'vue'
import { gsap } from 'gsap'

defineProps(['data'])

const circle = ref(null)
const innerCircle = ref(null)
const content = ref(null)
const infoContainer = ref(null)
const close = ref(null)
const activeItem = ref()
const isActive = ref(false)

const tl = gsap.timeline({
  paused: true,
  onComplete: () => {
    isActive.value = true
  },
})

const spinTl = gsap.timeline({
  paused: true,
  onComplete: () => {
    spinTl.reverse(-1)
  },
})

const circleTween = () => {
  tl.to(circle.value, 0.5, {
    left: 0,
    x: 0,
    y: 0,
    xPercent: -25,
    yPercent: -50,
    scale: 1.1,
  })
    .to(
      content.value,
      0.5,
      {
        top: -40,
        left: -40,
        autoAlpha: 0,
      },
      0
    )
    .to(
      infoContainer.value,
      0.5,
      {
        x: 0,
      },
      0
    )
    .to(
      close.value,
      0.3,
      {
        scale: 1,
        autoAlpha: 1,
      },
      0.4
    )
}

const animateCircle = (index) => {
  tl.play()
  activeItem.value = index

  if (isActive.value) {
    spinTl.play()
  }
}

const spinCircle = () => {
  spinTl.to(innerCircle.value, 0.25, {
    rotate: 720,
  })
}

const reverseCircle = () => {
  tl.reverse()
  activeItem.value = null
  isActive.value = false
}

onMounted(() => {
  circleTween()
  spinCircle()
})

onBeforeUnmount(() => {
  tl.kill()
})
</script>

<style lang="scss">
.our-approach {
  padding: 300px 0;
  overflow: hidden;

  @include tablet-landscape(1) {
    padding: 100px 0;
  }

  @include mobile {
    padding: 60px 0;
  }

  .approach-content {
    position: relative;
    max-width: 320px;

    @include tablet-landscape(1) {
      top: 0 !important;
      left: 0 !important;
      max-width: 100%;
      margin: 0 0 40px;
      opacity: 1 !important;
      visibility: visible !important;
    }

    .eyebrow {
      margin: 0 0 40px;
    }

    .headline {
      margin: 0 0 24px;
    }
  }

  .approach-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    max-width: 70%;

    @include tablet-landscape(1) {
      position: relative;
      width: 100%;
      max-width: 100%;
      top: 0 !important;
      left: 0 !important;
      transform: none !important;
    }

    @include mobile {
      width: 125%;
      max-width: 125%;
    }

    .c-inner {
      position: absolute;
      top: 2.5%;
      left: 4%;
      height: 93%;
    }

    .ac-btns {
      position: absolute;
      top: 0;
      left: 0;
      width: 70%;
      height: 100%;

      .title {
        cursor: pointer;
        position: absolute;
        right: 0;
        transform: translateY(-50%);

        &.active {
          pointer-events: none;

          .ac-btn {
            top: -40px;
            opacity: 0;
          }

          .title-headline {
            transform: translateY(-50%);
            opacity: 1;
          }
        }

        &:hover {
          .ac-btn:after {
            transform: rotate(-90deg);
          }
        }

        .ac-btn {
          position: relative;
          top: 0;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 6px 4px 24px;
          border-radius: 25px;
          border: 1px solid $light-gray;
          background: rgba(255, 255, 255, 0.3);
          backdrop-filter: blur(22.350000381469727px);
          @include transition;

          @include tablet-landscape {
            font-size: 1.6rem;
            padding: 2px 4px 2px 18px;
          }

          &:after {
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            background-image: url('@/assets/img/ac-btn.svg');
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 10px;
            transform: rotate(0deg);
            @include transition;
          }
        }

        .title-headline {
          position: absolute;
          top: 50%;
          opacity: 0;
          transform: translateY(100%);
          white-space: nowrap;
          @include transition;
        }

        &:first-child {
          top: 20%;
          right: 15%;
        }

        &:nth-child(2) {
          top: 50%;
          right: 7%;
        }

        &:nth-child(3) {
          top: 80%;
          right: 15%;
        }
      }
    }
  }

  .approach-info {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 70%;
    max-width: 780px;
    overflow: hidden;

    @media only screen and (min-width: 1550px) {
      right: 12%;
    }

    @media only screen and (max-width: 1350px) {
      width: 50%;
      right: 0;
    }

    @include tablet-landscape(1) {
      position: relative;
      top: 0;
      right: 0;
      width: 100%;
      max-width: 100%;
      transform: none !important;
    }

    &:not(.active) {
      pointer-events: none;
    }

    .approach-info-container {
      position: relative;
      width: 100%;
      transform: translateX(100%);
      padding: 20px;
    }

    .close {
      position: absolute;
      width: 40px;
      height: 40px;
      transform: translate(-40%, -40%) scale(0);
      visibility: hidden;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
    }

    .approach-item {
      position: relative;

      img {
        overflow: hidden;
        width: 100%;
        object-fit: cover;
        max-height: 360px;
        border-radius: 16px;
        margin: 0 0 30px;
      }

      .text {
        // columns: 2;
        // column-gap: 40px;
      }
    }
  }
}
</style>
