<template>
  <section
    id="join-us"
    class="careers-module-component module">
    <div class="container large">
      <div
        v-if="data.careers_headline"
        class="headline h1 font-med"
        v-html="data.careers_headline"></div>
      <div class="careers-wrapper">
        <div class="filters">
          <DropdownFilter
            v-model="selected[1]"
            :parent-selected="selected[1]"
            :options="locations"
            :default-val="defaultValue[1]"
            theme="orange"
            trigger-text="Locations" />
          <DropdownFilter
            v-model="selected[0]"
            :parent-selected="selected[0]"
            :options="categories"
            :default-val="defaultValue[0]"
            theme="blue"
            trigger-text="Departments" />
        </div>
        <template v-if="filteredJobs.length > 0">
          <template v-if="allJobs">
            <ul class="jobs-container jobs-wrapper">
              <li
                v-for="(job, idx) in filteredJobs"
                :key="`job-${idx}`"
                class="job-wrapper">
                <a
                  class="job"
                  :href="job.absolute_url"
                  target="_blank">
                  <div class="job-top">
                    <span class="category font-uppercase">{{
                      job.metadata[0].value[0]
                    }}</span>
                    <div class="locations">
                      <p
                        v-for="(location, index) in job.metadata[1].value"
                        :key="index">
                        {{ location }}
                      </p>
                    </div>
                  </div>
                  <span class="job-title font-larger">{{ job.title }}</span>
                  <button class="btn">View Position</button>
                </a>
              </li>
            </ul>
          </template>
          <template v-else>
            <ul class="jobs-container jobs-wrapper">
              <li
                v-for="(job, idx) in filteredJobs.slice(0, 6)"
                :key="`job-${idx}`"
                class="job-wrapper">
                <a
                  class="job"
                  :href="job.absolute_url"
                  target="_blank">
                  <div class="job-top">
                    <span class="category font-uppercase">{{
                      job.metadata[0].value[0]
                    }}</span>
                    <div class="locations">
                      <p
                        v-for="(location, index) in job.metadata[1].value"
                        :key="index">
                        {{ location }}
                      </p>
                    </div>
                  </div>
                  <span class="job-title font-larger">{{ job.title }}</span>
                  <button class="btn">View Position</button>
                </a>
              </li>
            </ul>
          </template>
        </template>
        <div
          v-else
          class="jobs-container no-jobs">
          <span v-if="selected == defaultValue"
            >There are no open positions. Please check back later.</span
          >
          <span
            >Sorry, we couldn't find any job postings that match your selected
            criteria</span
          >
        </div>
        <div
          v-if="filteredJobs.length > 6 && !allJobs"
          class="view-more"
          @click="showAllJobs">
          <p class="font-med">View More</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "CareersComponent",
    "CLASSNAME": "careers-module-component",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */

import { useStore } from '@/stores/main'
import { computed, onMounted, ref } from 'vue'
import DropdownFilter from '../utility/DropdownFilter.vue'
const store = useStore()
defineProps(['data'])

const defaultValue = ['All Departments', 'All Locations']
const categories = store.gh_jobs.custom_fields.category
const locations = store.gh_jobs.custom_fields.location
categories[0].name = defaultValue[0]
locations[0].name = defaultValue[1]
const jobs = ref([])
const selected = ref([[categories[0].name], [locations[0].name]])

const allJobs = ref(false)

const showAllJobs = () => {
  allJobs.value = true
}

const organizeJobs = () => {
  const rawJobs = store.gh_jobs.openings.jobs
  let categoriesCopy = [...categories]
  categoriesCopy.shift()
  jobs.value = categoriesCopy.map((category) => {
    return {
      category: category.name,
      jobs: [],
    }
  })

  jobs.value.forEach((category) => {
    category.jobs = rawJobs.filter((job) => {
      return category.category === job.metadata[0].value[0]
    })
  })
}

const filteredJobs = computed(() => {
  organizeJobs()

  let filtered = jobs.value

  if (selected.value[0][0] !== defaultValue[0]) {
    filtered = filtered.filter((job) => {
      return selected.value[0].includes(job.category)
    })
  }

  if (selected.value[1][0] !== defaultValue[1]) {
    filtered = filtered.map((job) => {
      return {
        ...job,
        jobs: job.jobs.filter((jobDetail) => {
          console.log()
          return jobDetail.metadata[1].value.some((e) =>
            selected.value[1].includes(e)
          )
        }),
      }
    })
  }

  let finalJobs = filtered
    .map((el) => {
      return el.jobs
    })
    .flat()

  return finalJobs
})

onMounted(() => {
  organizeJobs()
})
</script>

<style lang="scss">
.careers-module-component {
  @apply relative w-full  py-8 lg:py-10 px-6
  min-h-[320px];

  .headline {
    margin: 0 0 60px;

    @include mobile {
      margin: 0 0 40px;
    }
  }

  .careers-wrapper {
    position: relative;
  }

  .filters {
    @apply z-5 absolute top-0 right-0
    w-full flex flex-col gap-[7px]
    lg:px-0 lg:flex-row lg:justify-center lg:gap-[12px];
  }
  .jobs-container {
    @apply pt-[200px] lg:pt-[130px];
    &.jobs-wrapper {
      display: flex;
      flex-wrap: wrap;

      .job-wrapper {
        width: 32.5%;
        margin-right: 1.25%;
        margin-bottom: 1.25%;

        @include tablet-landscape(1) {
          width: 100%;
          margin-right: 0;
        }

        @include mobile {
          margin-bottom: 20px;
        }

        &:nth-child(3n + 3) {
          margin-right: 0;
        }

        .job {
          @apply flex flex-col items-center gap-[30px]
           p-[30px] rounded-[20px] w-full
           lg:gap-[40px];
          height: 100%;
          background: $light-gray;

          &-top {
            @apply flex flex-col items-center gap-[5px] 
            text-[16px];
            text-align: center;

            .category {
              @apply mb-[10px];
            }
          }

          &-title {
            @apply flex justify-center items-center h-[80px] max-w-[320px] text-center;
          }

          .btn {
            margin: auto 0 0;
          }
        }
      }
    }
    &.no-jobs {
      @apply flex justify-center;
      span {
        @apply leading-[1.2] font-medium text-5xl;
      }
    }
  }

  .view-more {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0;
    color: $indigo;

    &:before {
      content: '';
      display: block;
      width: 17.23px;
      height: 16px;
      margin-right: 8px;
      background-image: url('@/assets/img/dotted-arrow-down.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
</style>
