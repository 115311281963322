<template>
  <section
    id="news"
    ref="trigger"
    class="featured-news module">
    <div class="container">
      <div
        v-if="posts"
        class="featured-news-container">
        <span ref="line"></span>
        <div
          ref="lineTrigger"
          class="featured-news-wrapper">
          <div
            v-for="post in posts"
            :key="post.id"
            class="featured-news-item">
            <div class="dot"></div>
            <div class="date">
              <time>{{ formatDate(post.date) }}</time>
            </div>
            <div class="post-info">
              <div
                class="title font-larger"
                v-html="post.title.rendered"></div>
              <a
                :href="
                  post.acf.post_external ? post.acf.post_external : post.link
                "
                :target="post.acf.post_external ? '_blank' : ''"
                class="btn pink link">
                Read Article
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "FeaturedNewsComponent",
    "CLASSNAME": "featured-news",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { useStore } from '@/stores/main'
import { ref, onMounted, computed, onBeforeUnmount } from 'vue'
import { gsap, Linear } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const props = defineProps(['data'])

const trigger = ref(null)
const lineTrigger = ref(null)
const line = ref(null)

let tl
let tlLine

const request = {
  type: 'posts',
  params: {
    include: props.data.featured_news,
    orderby: 'include',
    _fields:
      'id,slug,title,featured_media,link,categories,excerpt,date,modified,content,acf',
  },
  showLoading: false,
}

const formatDate = (postDate) => {
  const date = new Date(postDate)

  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }

  return new Intl.DateTimeFormat('en-US', dateOptions).format(date)
}

const posts = computed(() => {
  return store.requestedItems(request)
})

const getPosts = () => {
  store.getItems(request).then(() => {
    tl = gsap.timeline({
      scrollTrigger: {
        trigger: trigger.value,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
      },
    })

    tl.to('.dot', {
      top: '50%',
      ease: Linear.easeNone,
    })
  })
}

const destroyTl = () => {
  tlLine.kill(true)
}

onMounted(() => {
  getPosts()

  tlLine = gsap.timeline({
    onComplete: destroyTl,
    scrollTrigger: {
      trigger: lineTrigger.value,
      start: 'center center',
    },
  })

  tlLine.to(line.value, {
    height: '100%',
    duration: 1,
  })
})

onBeforeUnmount(() => {
  tl.kill()

  if (tlLine) {
    destroyTl()
  }
})
</script>

<style lang="scss">
.featured-news {
  .featured-news-container {
    position: relative;
    padding: 120px 0;

    @include mobile {
      padding: 100px 0;
    }

    span {
      display: block;
      position: absolute;
      top: 0;
      left: 15%;
      width: 1px;
      height: 0%;
      background: linear-gradient(
        180deg,
        rgba(239, 58, 98, 0) 0%,
        rgba(239, 58, 98, 1) 25%,
        rgba(100, 189, 255, 1) 50%,
        rgba(83, 64, 226, 1) 75%,
        rgba(83, 64, 226, 0) 100%
      );

      @include mobile {
        left: 5%;
      }
    }
  }

  .featured-news-item {
    position: relative;
    display: flex;
    padding: 0 0 40px;
    margin: 0 0 40px;
    border-bottom: 1px solid $light-gray;

    @include mobile {
      flex-direction: column;
      padding-left: 50px;
    }

    &:first-child {
      .dot {
        background: $cerise;
        box-shadow:
          0px 0px 12px $cerise,
          0px 0px 28px $cerise;
      }
    }

    &:nth-child(2) {
      .dot {
        background: $blue;
        box-shadow:
          0px 0px 12px $blue,
          0px 0px 28px $blue;
      }
    }

    &:last-child {
      .dot {
        background: $indigo;
        box-shadow:
          0px 0px 12px $indigo,
          0px 0px 28px $indigo;
      }
    }

    .dot {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 15%;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: translateX(-50%);

      @include mobile {
        left: 5%;
      }
    }

    &:last-child {
      padding: 0;
      margin: 0;
      border: none;

      @include mobile {
        padding-left: 50px;
      }
    }

    .date {
      width: 10%;
      margin-right: 10%;
    }

    .line {
      position: absolute;
    }

    .post-info {
      display: flex;
      flex-direction: column;
      flex: 1;

      .btn {
        align-self: flex-start;
        margin: 24px 0 0;
      }
    }
  }
}
</style>
