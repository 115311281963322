<template>
  <div
    class="accordion"
    :class="{ active: open }"
    @click="toggleAccordion">
    <div class="accordion-btn">
      <div
        class="title h3 font-med"
        v-html="item.accordion_item_title"></div>
    </div>
    <Vue3SlideUpDown
      v-model="open"
      :duration="200"
      :timing-function="'linear'">
      <div class="accordion-content">
        <template v-if="item.accordion_item_video">
          <video
            loop
            muted
            autoplay
            playsinline
            preload="auto">
            <source
              :src="item.accordion_item_video.url"
              type="video/mp4" />
          </video>
        </template>
        <template v-else-if="item.accordion_item_image">
          <img
            :src="item.accordion_item_image.url"
            alt="" />
        </template>
        <div
          class="text editor-formatting"
          v-html="item.accordion_item_text"></div>
      </div>
    </Vue3SlideUpDown>
  </div>
</template>

<script setup>
/* #META
   {
    "NAME": "AccordionsComponent",
    "CLASSNAME": "accordions",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { ref } from 'vue'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

const open = ref(false)

// const openAccordion = () => {
//   open.value = true
// }

// const closeAccordion = () => {
//   open.value = false
// }

const toggleAccordion = () => {
  open.value = !open.value
}

defineProps(['item'])
</script>

<style lang="scss">
.accordion {
  position: relative;
  margin: 0 0 16px;
  border-radius: 16px;
  background-color: $dark-gray;

  &:last-child {
    margin: 0;
  }

  &.active {
    color: $black;
    background-color: $light-gray;

    .accordion-btn {
      &:after {
        background-image: url('@/assets/img/icon-accordion-open.svg');
      }
    }
  }

  .accordion-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    cursor: pointer;

    @include mobile {
      padding: 20px;
    }

    &:after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      background-image: url('@/assets/img/icon-accordion.svg');
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 1;
    }

    .title {
      // @include transition;
    }
  }

  .accordion-content {
    display: flex;
    align-items: center;
    padding: 24px 40px 40px;

    @include tablet-landscape(1) {
      flex-direction: column;
      padding: 20px 25px 25px;
    }

    .imager,
    img,
    video {
      width: 50%;
      margin-right: 5%;
      border-radius: 16px;
      overflow: hidden;

      @include tablet-landscape(1) {
        width: 100%;
        margin: 0 0 40px;
      }
    }

    .text {
      flex: 1;
    }
  }
}
</style>
