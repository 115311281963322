<template>
  <section class="mission-carousel module">
    <div class="container">
      <div class="mcara-intro">
        <div
          class="eyebrow"
          v-html="data.mission_carousel_tagline"></div>
        <div
          class="headline h3"
          v-html="data.mission_carousel_headline"></div>
        <div
          class="text editor-formatting font-larger"
          v-html="data.mission_carousel_intro_text"></div>
      </div>
      <div class="mcara-carousel-container">
        <div class="mcara-carousel">
          <Splide :options="options">
            <SplideSlide
              v-for="(item, index) in data.mission_carousel"
              :key="index">
              <div class="mcara-carousel-item">
                <img
                  v-lazy="item.mission_carousel_image.url"
                  :alt="item.mission_carousel_image.alt" />
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div
          class="mcara-text editor-formatting"
          v-html="data.mission_carousel_text"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "MissionCarouselComponent",
    "CLASSNAME": "mission-carousel",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css/core'

defineProps(['data'])

const options = {
  pagination: false,
  arrows: false,
  interval: 4000,
  rewind: true,
  autoplay: true,
  speed: 1000,
  type: 'fade',
}
</script>

<style lang="scss">
.mission-carousel {
  .mcara-intro {
    margin: 0 0 40px;

    .eyebrow {
      margin: 0 0 40px;
    }

    .headline {
      margin: 0 0 24px;
    }
  }

  .mcara-carousel-container {
    display: flex;
    justify-content: space-between;

    @include tablet-landscape(1) {
      flex-direction: column;
    }

    .mcara-carousel {
      width: 50%;
      max-width: 548px;
      margin-right: 5%;

      @include tablet-landscape(1) {
        width: 100%;
        margin: 0 0 40px;
      }

      .mcara-carousel-item {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        padding-bottom: 87%;
        border-radius: 16px;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .mcara-text {
      flex: 1;
    }
  }
}
</style>
