<template>
  <section class="benefits module">
    <div
      ref="trigger"
      class="container top">
      <div class="benefit-row top">
        <div class="content-wrapper">
          <div class="content">
            <div
              class="eyebrow"
              v-html="data.benefits_tagline"></div>
            <div
              class="headline h3 font-med"
              v-html="data.benefits_headline"></div>
          </div>
          <Imager
            :image="data.benefits_img1"
            :left="true"
            class="img-1" />
        </div>
      </div>
    </div>
    <div class="benefits-container">
      <div
        v-for="(item, index) in data.benefits_list"
        :key="index"
        class="benefit"
        v-html="item.benefit_list_title"></div>
    </div>
    <Imager
      :image="data.benefits_img2"
      :right="true"
      class="img-2" />
    <Imager
      :image="data.benefits_img3"
      :left="true"
      class="img-3" />
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "BenefitsComponent",
    "CLASSNAME": "benefits",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import Imager from '@/components/utility/Imager.vue'
import { gsap, Linear } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { ref, onMounted, onBeforeUnmount } from 'vue'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const trigger = ref(null)

let tl

onMounted(() => {
  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
    },
  })

  tl.to('.img-1', {
    left: '-10%',
    ease: Linear.easeNone,
  })
    .to(
      '.img-2',
      {
        top: '130px',
        ease: Linear.easeNone,
      },
      0
    )
    .to(
      '.img-3',
      {
        top: '-90px',
        ease: Linear.easeNone,
      },
      0
    )
})

onBeforeUnmount(() => {
  tl.kill()
})
</script>

<style lang="scss">
.benefits {
  padding-bottom: 0 !important;

  .benefit-row {
    &.top {
      display: flex;
      justify-content: space-between;

      .content-wrapper,
      .img-2 {
        width: 49%;
      }

      .content-wrapper,
      .img-1 {
        @include tablet-landscape(1) {
          width: 100%;
        }
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 0 100px;

        .eyebrow {
          margin: 0 0 40px;
        }

        .content {
          margin: 0 0 60px;

          @include tablet-landscape(1) {
            order: 2;
            margin: 40px 0 0;
          }
        }

        .img-1 {
          position: relative;
          width: 50%;
          align-self: flex-end;

          @media only screen and (max-width: 1350px) {
            align-self: center;
          }

          @media only screen and (max-width: 1200px) {
            align-self: flex-start;
            left: 10%;
          }

          @include tablet-landscape(1) {
            width: 100%;
          }
        }
      }
    }
  }

  .img-2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 700px;
    max-width: 49%;

    @include tablet-landscape(1) {
      display: none;
    }
  }

  .img-3 {
    margin-top: 10px;
    margin-left: 2.5%;

    @include tablet-landscape(1) {
      width: 90%;
      margin: 20px auto 0;
    }
  }

  .benefits-container {
    position: absolute;
    top: 30%;
    right: 5%;
    display: flex;
    flex-wrap: wrap;
    width: 720px;
    margin-left: auto;
    z-index: 1;

    @include tablet-landscape(1) {
      position: relative;
      top: 0;
      right: 0;
      width: 90%;
      margin: auto;
    }

    .benefit {
      display: flex;
      align-items: center;
      width: 234px;
      height: 138px;
      padding: 16px;
      margin-right: 9px;
      margin-bottom: 11px;
      border-radius: 10px;
      border: 1px solid $indigo;
      background: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(14.600000381469727px);

      &:nth-child(3n) {
        margin-right: 0;
      }

      @include tablet-landscape(1) {
        width: 32.33%;
        margin-right: 1.5%;
        margin-bottom: 1.5%;

        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }

      @include mobile {
        width: 48%;
        margin-right: 2%;

        &:nth-child(3n + 3) {
          margin-right: 2%;
        }

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
