<template>
  <div class="header-wrapper">
    <header
      id="header-nav"
      ref="header"
      class="header-nav"
      :class="`${navOpen ? 'nav-open' : ''} ${direction} ${
        scrolling ? 'scrolling' : ''
      }`">
      <div class="nav-toggle">
        <div class="nav-toggle-wrapper">
          <div class="nav-logo">
            <a
              href="/"
              @click="scrollToTop">
              <div
                ref="animation"
                class="logo"></div>
            </a>
          </div>
          <div
            class="hamburger"
            :class="{ open: navOpen }"
            @click="open">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="nav-back">
            <a
              class="btn-back font-smaller"
              @click="goBack()">
              <img
                class="arrow"
                src="@/assets/img/icon-arrow-back.svg"
                alt="Back Arrow" />
              <p>Back</p>
            </a>
          </div>
        </div>
      </div>

      <transition name="slide-fade">
        <div
          v-if="navOpen"
          class="nav-menu">
          <div
            class="nav-container"
            data-lenis-prevent>
            <nav>
              <div
                class="nav-wrapper"
                :class="`${navOpen ? 'nav-open' : ''} `"
                data-lenis-prevent>
                <ul ref="nav">
                  <template
                    v-for="item in menu"
                    :key="item.id">
                    <li v-if="item.parent === '0'">
                      <template v-if="isExternal(item.url)">
                        <a
                          :href="item.url"
                          :target="item.target"
                          class="no-router font-larger"
                          :tabindex="active ? 0 : -1"
                          @click="close"
                          v-html="item.content"></a>
                      </template>
                      <template v-else>
                        <RouterLink
                          :to="convertToRelative(item.url)"
                          exact
                          class="font-larger"
                          @click="close"
                          v-html="item.content">
                        </RouterLink>
                      </template>
                    </li>
                  </template>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </transition>
    </header>
    <transition name="fade">
      <div
        v-if="navOpen"
        class="nav-overlay"
        @click="close"></div>
    </transition>
  </div>
</template>

<script setup>
import useUtils from '@/components/composables/useUtils.js'
import { ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '@/stores/main'
import { formatInTimeZone } from 'date-fns-tz'
import useIntroShown from '@/components/composables/useIntroShown.js'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'
import LottieWeb from 'lottie-web'
import logoJson from '@/assets/lottie/logo.json'

const router = useRouter()
const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const header = ref(null)
const navOpen = ref(false)
const direction = ref('up')
const timeNY = ref()
const timeCAL = ref()
// const scrollTop = ref(0)
const scrolling = ref(false)
const { introShown } = useIntroShown()
const animation = ref()
const { convertToRelative, isExternal } = useUtils()

const { locoScroll } = useSmoothScroll()

defineProps(['navState'])

const open = () => {
  if (navOpen.value) {
    navOpen.value = false
    locoScroll.value.start()
  } else {
    navOpen.value = true
    locoScroll.value.stop()
  }
}

const close = () => {
  navOpen.value = false
  locoScroll.value.start()
}

const hasIntroShown = () => {
  if (introShown.value) {
    LottieWeb.loadAnimation({
      container: animation.value,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      animationData: logoJson,
    })
  } else {
    setTimeout(() => {
      hasIntroShown()
    }, 100)
  }
}

const escClose = (e) => {
  if (e.keyCode === 27) {
    close()
  }
}

// function handleScroll() {
//   const scrollDir = locoScroll.value.direction

//   if (scrollDir == -1 || scrollDir == 0) {
//     direction.value = 'up'
//   } else {
//     direction.value = 'down'
//   }

//   scrollTop.value = window.pageYOffset
//   if (scrollTop.value >= 100) {
//     scrolling.value = true
//   } else {
//     direction.value = 'up'
//     scrolling.value = false
//   }
// }

const goBack = () => {
  router.go(-1)
}

const scrollToTop = () => {
  close()

  locoScroll.value.scrollTo('top', {
    immediate: false,
  })
}

onMounted(() => {
  hasIntroShown()
  document.addEventListener('keyup', escClose, { passive: true })
  // document.addEventListener('scroll', handleScroll, { passive: true })

  setInterval(() => {
    let date = new Date()
    timeNY.value = formatInTimeZone(date, 'America/New_York', 'h:mm a')
    timeCAL.value = formatInTimeZone(date, 'America/Los_Angeles', 'h:mm a')
  }, 1000)
})

onUnmounted(() => {
  document.removeEventListener('keyup', escClose, { passive: true })
  // document.removeEventListener('scroll', handleScroll, false)
})
</script>

<style lang="scss">
.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  pointer-events: none;

  @include mobile {
    pointer-events: all;
  }

  .nav-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(30px);
    z-index: -1;
  }

  &.compact {
    .hamburger {
      display: none;
    }

    .nav-toggle-wrapper .nav-back {
      display: flex;
    }
  }
}

.header-nav {
  position: relative;
  width: 95%;
  margin: 20px auto 0;

  &.nav-open {
    .nav-toggle-wrapper {
      background: rgba(0, 0, 0, 0);
      backdrop-filter: blur(0px);
    }
  }

  .nav-toggle {
    position: relative;
    display: flex;
  }

  .nav-toggle-wrapper {
    display: flex;
    align-items: center;
    padding: 12px 20px 12px 12px;
    border-radius: 8px;
    background: rgba(44, 44, 44, 0.8);
    backdrop-filter: blur(22px);
    z-index: 1;
    pointer-events: all;
    @include transition;

    @include mobile {
      width: 100%;
      justify-content: space-between;
    }

    .nav-logo {
      a {
        display: block;

        .logo {
          width: 120px;
        }
      }
    }

    .hamburger {
      cursor: pointer;
      position: relative;
      width: 25px;
      height: 18px;
      margin-left: 35px;

      &.open {
        span {
          width: 115%;

          &:first-child {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }

      span {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        background-color: $blue;
        @include transition;

        &:first-child {
          top: 0;
        }

        &:nth-child(2) {
          top: calc(50% + 1px);
          transform: translateY(-50%);
        }

        &:last-child {
          top: 100%;
        }
      }
    }

    .nav-back {
      display: none;
      cursor: pointer;
      position: relative;
      margin-left: 35px;

      .btn-back {
        display: flex;

        img {
          margin-right: 8px;
        }

        p {
          font-size: 1.6rem;
          line-height: 1;
          color: $blue;
          @include font-main-med;
        }
      }
    }
  }

  .nav-menu {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 660px;
    height: calc(100vh - 50px);
    pointer-events: all;

    @include tablet-landscape(1) {
      max-width: 100%;
    }

    .nav-container {
      position: relative;
      width: 100%;
      max-height: 100%;
      padding: 140px 0 0;
      border-radius: 8px;
      background: rgba(44, 44, 44, 0.4);
      backdrop-filter: blur(22.350000381469727px);
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      @include mobile {
        padding-bottom: 50px;
      }
    }

    nav {
      width: 80%;
      margin: auto;

      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include mobile {
          flex-direction: column;
        }

        li {
          width: 45%;
          padding: 0 0 30px;
          margin: 0 0 30px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);

          @include mobile {
            width: 100%;
          }

          a {
            color: $white;
          }
        }
      }
    }

    .offices {
      padding: 70px 0;
      margin: 60px 0 0;
      border-radius: 8px;
      background: $blue;

      @include mobile {
        display: none;
      }

      .offices-wrapper {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin: auto;

        .location {
          display: flex;
          flex-direction: column;
          margin-right: 10%;

          &:last-child {
            margin: 0;
          }

          time {
            display: block;
            margin: 0 0 18px;
            @include font-main-med;
          }

          .location-name {
            align-self: flex-start;
            font-size: 1.4rem;
            text-transform: uppercase;
            padding: 0 9px;
            margin: 0 0 18px;
            border-radius: 17px;
            border: 1px solid $dark-gray;
          }
        }
      }
    }
  }
}
</style>
