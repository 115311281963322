<template>
  <div class="post-meta">
    <div
      v-if="props.post.categories.length"
      class="categories">
      <ArchiveLink
        v-for="category in props.post.categories"
        :key="category"
        archive-type="categories"
        :archive-id="category"
        class="post-category" />
    </div>
  </div>
</template>

<script setup>
import ArchiveLink from '@/components/utility/ArchiveLink.vue'

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
})
</script>

<style lang="scss"></style>
