<template>
  <section
    id="contact"
    class="contact-form module">
    <div class="background">
      <div class="bg-shape"></div>
    </div>
    <div class="container med">
      <div class="contact-form-wrapper">
        <div class="form-container">
          <div class="form-group">
            <div class="form-group-top">
              <div
                class="form-section-title h2"
                v-html="props.data.form_section_title"></div>
            </div>
            <div class="form-area">
              <FormKit
                v-if="form"
                id="formKit"
                type="form"
                @submit="submitHandler">
                <FormKitSchema
                  :schema="selectedForm"
                  :library="library" />
              </FormKit>
            </div>
          </div>
        </div>
        <div class="contact-content">
          <div
            v-if="data.form_section_report_text"
            class="concern-box">
            <div
              v-if="data.form_section_report_title"
              class="concern-title">
              {{ data.form_section_report_title }}
            </div>
            <div
              class="concern font-smaller editor-formatting"
              v-html="data.form_section_report_text"></div>
          </div>
          <div
            v-if="
              data.form_section_callout_headline ||
              data.form_section_callout_link
            "
            class="jobs-callout">
            <div
              v-if="data.form_section_callout_headline"
              class="headline h5"
              v-html="data.form_section_callout_headline"></div>
            <a
              v-if="data.form_section_callout_link"
              :href="data.form_section_callout_link.url"
              :target="data.form_section_callout_link.target"
              class="btn"
              >{{ data.form_section_callout_link.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
 {
  "NAME": "ContactFormComponent",
  "CLASSNAME": "contact-form",
  "URL": "http://eikon.local/"
 }
#ENDMETA */
import { ref, computed, onBeforeMount } from 'vue'
import { useStore } from '../../stores/main'
import { FormKitSchema } from '@formkit/vue'
import { reset } from '@formkit/core'
import Axios from 'axios'

const props = defineProps(['data'])
const store = useStore()
const disabled = ref(false)
const message = ref('')
const library = ref({})
const form = ref(null)
const request = ref({
  type: 'forms',
  id: props.data.form_section_id,
})

const selectOptions = (choices) => {
  if (choices) {
    return choices.map((choice) => {
      return choice.text
    })
  } else {
    return []
  }
}

const validationTypes = (required, type) => {
  const validation = []
  if (required) {
    validation.push('required')
  }
  if (type === 'email') {
    validation.push('email')
  }
  if (type === 'phone') {
    validation.push('number')
    validation.push('length:10,10')
  }
  const finalValidation = validation.join('|')
  return finalValidation
}

const selectedForm = computed(() => {
  const schema = []
  form.value.fields.forEach((field) => {
    schema.push({
      $formkit: field.type === 'phone' ? 'tel' : field.type,
      name: field.label,
      // label: field.label,
      placeholder: field.placeholder,
      suffix: '',
      options: selectOptions(field.choices),
      classes: {
        outer: field.cssClass,
      },
      validation: validationTypes(field.isRequired, field.type),
    })
  })
  schema.push({
    $el: 'div',
    children: message.value.replace(/<\/?[^>]+(>|$)/g, ''),
    attrs: {
      class: 'message-container',
    },
  })
  return schema
})

const getTheForm = () => (form.value = store.singleById(request.value))

const getForms = () => {
  return store.getSingleById(request.value).then(() => getTheForm())
}

const postData = async (URL, body) => {
  try {
    const response = await Axios.post(URL, body)
    message.value = response.data.confirmation_message
      ? response.data.confirmation_message
      : response.data.validation_messages[1]
    reset('formKit')
    disabled.value = false
  } catch (error) {
    console.error(error)
  }
}

const submitHandler = (data) => {
  // console.log(form.value)
  disabled.value = true
  let curedForm = {}
  for (const field of Object.entries(data)) {
    curedForm[
      `input_${
        form.value.fields.find((x) => {
          return x.label === field[0]
        }).id
      }`
    ] = field[1]
  }
  postData(form.value.submitURL, curedForm)
}

onBeforeMount(() => getForms())
</script>

<style lang="scss">
.contact-form {
  color: $white;
  background-color: $black;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .bg-shape {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('@/assets/img/contact-lines.svg');
      background-repeat: no-repeat;
      background-position: 90% top;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }

  .contact-form-wrapper {
    display: flex;
    justify-content: space-between;

    @include tablet-landscape(1) {
      flex-direction: column;
    }

    .form-container {
      width: 55%;
      max-width: 550px;
      margin-right: 40px;

      @include tablet-landscape(1) {
        width: 100%;
        max-width: 100%;
        margin: 0 0 50px;
      }

      .form-group-top {
        margin: 0 0 40px;

        .form-section-title + .form-section-text {
          margin: 20px 0 0;
        }
      }
    }

    .contact-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 433px;

      @include tablet-landscape(1) {
        max-width: 100%;
      }
    }

    .concern-box {
      padding: 60px;
      margin: 0 0 80px;
      border-radius: 20px;
      background: rgba(44, 44, 44, 0.4);
      backdrop-filter: blur(12px);

      @include tablet-landscape {
        padding: 60px;
      }

      @include mobile {
        padding: 40px;
      }

      .concern-title {
        margin: 0 0 20px;
        text-transform: uppercase;
        color: $orange;
        @include font-main-xtrabold;
      }
    }

    .jobs-callout {
      display: flex;
      flex-direction: column;
      padding: 60px;
      border-radius: 20px;
      background: rgba(44, 44, 44, 0.4);
      backdrop-filter: blur(12px);

      @include tablet-landscape {
        padding: 60px;
      }

      @include mobile {
        padding: 40px;
      }

      .btn {
        align-self: flex-start;
      }

      .headline + .btn {
        margin: 40px 0 0;
      }
    }
  }
}
</style>
