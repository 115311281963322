<template>
  <div
    v-if="post"
    class="hero-featured-post">
    <a
      :href="post.acf.post_external ? post.acf.post_external : post.link"
      :title="post.post_title"
      :target="post.acf.post_external ? '_blank' : ''">
      <div class="post-head">
        <div class="eyebrow-wrapper">
          <div class="eyebrow reg-mobile">Featured News</div>
        </div>
        <div class="post-date-source">
          <time>{{ formatDate(post.date) }}</time>
          <span v-if="post.acf.post_source">|</span>
          <div
            v-if="post.acf.post_source"
            class="post-source"
            v-html="post.acf.post_source"></div>
        </div>
      </div>
      <div class="title-btn">
        <p
          class="title h2"
          v-html="post.title.rendered"></p>
        <p class="btn blue">Read Full Article</p>
      </div>
    </a>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from '@/stores/main'

const props = defineProps(['postID'])

const store = useStore()

const request = ref({
  type: 'posts',
  id: props.postID,
  batch: true,
})

const formatDate = (postDate) => {
  const date = new Date(postDate)

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return new Intl.DateTimeFormat('en-US', dateOptions).format(date)
}

const post = computed(() => {
  return store.singleById(request.value)
})

const getPost = () => {
  store.getSingleById(request.value)
}

onMounted(() => {
  getPost()
})
</script>

<style lang="scss">
.general-content {
  .section-intro {
    margin-bottom: 0;
  }
}
</style>
