<template>
  <article class="post">
    <div class="date-category">
      <div class="post-date-source">
        <time>{{ formatDate(props.post.date) }}</time>
        <span v-if="props.post.acf.post_source">|</span>
        <div
          v-if="props.post.acf.post_source"
          class="post-source"
          v-html="props.post.acf.post_source"></div>
      </div>
      <PostTaxonomies :post="props.post" />
    </div>
    <div class="title-btn">
      <div class="title font-larger">
        <div
          class="title-actual"
          v-html="props.post.title.rendered"></div>
        <div
          v-if="post.excerpt.rendered"
          class="post-expand"
          :class="{ open: open }"
          @click="toggleExcerpt"></div>
      </div>
      <a
        :href="
          post.acf.post_external ? post.acf.post_external : props.post.link
        "
        :title="props.post.post_title"
        :target="post.acf.post_external ? '_blank' : ''"
        class="btn blue">
        Read Full Article
      </a>
    </div>
    <template v-if="post.excerpt.rendered">
      <Vue3SlideUpDown v-model="open">
        <div
          class="post-excerpt"
          v-html="post.excerpt.rendered"></div>
      </Vue3SlideUpDown>
    </template>
  </article>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import PostTaxonomies from '@/components/utility/PostTaxonomies.vue'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

const videoSrc = ref(false)
const open = ref(false)

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
})
const formatDate = (postDate) => {
  const date = new Date(postDate)

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return new Intl.DateTimeFormat('en-US', dateOptions).format(date)
}

const urlSrc = (src) => {
  try {
    const domain = new URL(src)

    if (domain.hostname == 'www.youtube.com') {
      videoSrc.value = true
    }
  } catch (error) {
    return false
  }
}

const toggleExcerpt = () => {
  open.value = !open.value
}

onMounted(() => {
  if (props.post.acf.post_external) {
    urlSrc(props.post.acf.post_external)
  }
})
</script>

<style lang="scss">
.post {
  padding: 40px 5%;
  border: 1px solid $light-gray;

  &:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:not(:first-child) {
    border-top: none;
  }

  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .date-category {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px;

    @include mobile {
      flex-direction: column;
    }

    .post-date-source,
    .post-meta {
      font-size: 18px;
      letter-spacing: 1.44px;
      text-transform: uppercase;
      @include font-main-med;
    }

    .post-date-source {
      display: flex;
      color: rgba(44, 44, 44, 0.4);

      span {
        margin: 0 7px;
      }
    }

    .post-meta {
      color: $orange;

      @include mobile {
        margin: 10px 0 0;
      }
    }
  }

  .post-expand {
    cursor: pointer;
    display: block;
    width: 40px;
    height: 40px;
    margin-left: 20px;
    background-image: url('@/assets/img/icon-accordion.svg');
    background-repeat: no-repeat;
    background-size: contain;

    &.open {
      background-image: url('@/assets/img/icon-accordion-open.svg');
    }
  }

  .title-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      margin-right: 20px;

      @include mobile {
        margin: 0 0 40px;
      }

      .title-actual {
        flex: 1;
      }
    }
  }

  .post-excerpt {
    padding: 40px 0 0;
  }
}
</style>
