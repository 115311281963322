<template>
  <div
    ref="dropdown"
    class="dd-filter"
    :class="`theme-${theme}`">
    <div
      class="dd-filter-trigger"
      :class="{ down: showDropdown }">
      <span class="font-larger">
        {{ triggerText }}
      </span>
      <button
        class="arrow-btn"
        :class="{ down: showDropdown }"
        @click="switchDropdown"></button>
      <button
        v-if="selected[0] != defaultVal"
        class="clear"
        @click="resetValues(e, true)">
        Clear
      </button>
    </div>
    <Vue3SlideUpDown v-model="showDropdown">
      <ul
        class="dd-filter-options"
        data-lenis-prevent>
        <li
          v-for="(option, idx) in options"
          :key="`filter-dd-${idx}`"
          class="option"
          :class="{
            ['active-filter']: selected.includes(option.name),
          }"
          @click="selectFilter(option, 0)">
          <div class="option-name">{{ option.name }}</div>
          <div class="radio" />
        </li>
      </ul>
    </Vue3SlideUpDown>
  </div>
</template>

<script setup>
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import { ref, watchEffect, onMounted, onBeforeMount } from 'vue'

const props = defineProps([
  'parentSelected',
  'options',
  'modelValue',
  'defaultVal',
  'theme',
  'triggerText',
])

const emit = defineEmits(['update:modelValue'])

const selected = ref(props.modelValue)
const dropdown = ref()

const showDropdown = ref(false)

const addValue = (value) => {
  let prevArr = selected.value
  let newArr = []
  if (prevArr.includes(value)) {
    newArr = prevArr.filter((item) => item !== value)
  } else {
    newArr = [...selected.value, value]
  }
  if (newArr.length === 0) {
    resetValues()
  } else {
    selected.value = newArr.filter((value) => value !== props.defaultVal)
    emit('update:modelValue', selected.value)
  }
}

const resetValues = (e, close) => {
  if (e) {
    e.stopPropagation()
  }
  selected.value = [props.defaultVal]
  emit('update:modelValue', selected.value)
  if (close) {
    showDropdown.value = false
  }
}

const switchDropdown = () => {
  showDropdown.value = !showDropdown.value
}
const selectFilter = (option) => {
  if (option.name === props.defaultVal) {
    resetValues()
  } else {
    addValue(option.name)
  }
}

watchEffect(() => {
  selected.value = props.parentSelected
})

const closeFiltersOnClick = (e, element) => {
  if (element) {
    if (!element.contains(e.target) && showDropdown.value) {
      showDropdown.value = false
    }
  }
}
onMounted(() => {
  document.body.addEventListener(
    'click',
    (event) => {
      closeFiltersOnClick(event, dropdown.value)
    },
    true
  )
})

onBeforeMount(() => {
  document.body.removeEventListener(
    'click',
    (event) => {
      closeFiltersOnClick(event, dropdown.value)
    },
    true
  )
})
</script>

<style lang="scss" scoped>
.dd-filter {
  @apply relative 
  lg:w-[350px] overflow-visible
  lg:h-[56px] bg-white rounded-[20px];

  &-trigger {
    @apply rounded-[28px] h-full
    flex items-center justify-between;
    padding: 8px 8px 8px 24px;
    background-color: $light-gray;
    @include transition;

    .arrow-btn {
      width: 40px;
      height: 40px;
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(0deg);
      @include transition;

      &.down {
        transform: rotate(180deg);
      }
    }
    .clear {
      @apply text-white bg-black absolute top-[2px] left-[27px] z-5 flex justify-center items-center 
      w-[51px] h-[15px] 
      text-[10px] leading-[24px] tracking-[-0.22px] text-center rounded-[100px];
    }
  }

  &-options {
    @apply z-6 w-full flex flex-col max-h-[200px] overflow-y-scroll cursor-pointer rounded-[28px]
    lg:absolute top-[0];
    padding-top: 56px;
    border-radius: 28px;
    background: rgba(226, 226, 226, 0.4);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20px);
    z-index: -1;

    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 999px;
      background: rgba(0, 0, 0, 0.2);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.3);
    }
    .option {
      @apply flex justify-between items-center pl-[27px] pr-[36px]  py-[7.5px]  text-[16px] font-normal leading-[24px] tracking-[-0.308px]
      border-t border-solid border-t-[#E3E3E3];

      .option-name {
        flex: 1;
        margin-right: 10px;
      }
      .radio {
        @apply relative rounded-full border w-[24px] h-[24px] border-solid border-[rgba(0,0,0,0.30)] transition-all;

        &::before {
          @apply transition-opacity opacity-0 absolute -translate-x-2/4 -translate-y-2/4 content-[''] 
          h-[16px] w-[16px] bg-black rounded-[100%] left-2/4 top-2/4;
        }
      }
      &.active-filter {
        .radio {
          &::before {
            @apply opacity-100;
          }
        }
      }
    }
  }

  &.theme-orange {
    .clear {
      @apply bg-[#f3883a];
      color: $off-black;
    }

    .arrow-btn {
      background-image: url('@/assets/img/circle-button-orange.png');
    }

    .radio {
      &::before {
        @apply bg-[#F3883A];
        box-shadow:
          0px 0px 62.8px #f3883a,
          0px 0px 11.6px #f3883a;
      }
    }
  }

  &.theme-blue {
    .clear {
      @apply bg-[#64bdff];
    }

    .arrow-btn {
      background-image: url('@/assets/img/circle-button-blue.png');
    }

    .radio {
      &::before {
        @apply bg-[#64bdff];
        box-shadow:
          0px 0px 62.8px #64bdff,
          0px 0px 11.6px #64bdff;
      }
    }
  }
}
</style>
