<template>
  <section
    ref="trigger"
    class="tabs module">
    <div
      ref="tabsContainer"
      class="tabs-container">
      <div class="filter dark">
        <div class="filter-wrapper">
          <div
            v-for="(tab, index) in data.tabs"
            :key="index"
            class="category"
            :class="{ active: currentTab == `tab-${index + 1}` }">
            <div v-html="tab.tab_title"></div>
          </div>
        </div>
      </div>
      <div class="tabs-wrapper">
        <template
          v-for="(item, index) in data.tabs"
          :key="index">
          <div
            ref="tabs"
            class="tab-content">
            <div class="tab-copy">
              <div
                class="headline h1 font-med"
                v-html="item.tab_headline"></div>
              <div
                class="text editor-formatting"
                v-html="item.tab_text"></div>
            </div>
            <template v-if="item.tab_image_video">
              <video
                loop
                muted
                autoplay
                playsinline
                preload="auto">
                <source
                  :src="item.tab_image_video.url"
                  type="video/mp4" />
              </video>
            </template>
            <template v-else-if="item.tab_image">
              <img
                :src="item.tab_image.url"
                :alt="item.tab_image.alt" />
            </template>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TabsComponent",
    "CLASSNAME": "tabs",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { ref, onBeforeUnmount, onMounted } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

defineProps(['data'])

const trigger = ref(null)
const tabsContainer = ref(null)
const tabs = ref(null)
const currentTab = ref('tab-1')

let tlTabs

// const seekTab = (num) => {
//   gsap.to(window, {
//     scrollTo: tlTabs.scrollTrigger.labelToScroll(`tab-${num + 1}`),
//   })
// }

const updatePager = (progress) => {
  if (ScrollTrigger.isInViewport(tabsContainer.value)) {
    let roundedProgress = Math.round((progress + Number.EPSILON) * 100) / 100
    if (roundedProgress <= 0.3) {
      currentTab.value = 'tab-1'
    } else if (roundedProgress >= 0.31 && roundedProgress <= 0.68) {
      currentTab.value = 'tab-2'
    } else if (roundedProgress >= 0.69) {
      currentTab.value = 'tab-3'
    }
  }
}

onMounted(() => {
  ScrollTrigger.matchMedia({
    '(min-width: 1024px) and (min-height: 700px)': () => {
      tlTabs = gsap.timeline({
        scrollTrigger: {
          trigger: tabsContainer.value,
          start: 'top top',
          end: 'bottom bottom',
          pin: tabsContainer.value,
          pinSpacing: false,
          scrub: true,
          anticipatePin: 1,
          toggleActions: 'play pause play reset',
          onUpdate: (self) => updatePager(self.progress),
        },
      })

      tlTabs
        .to(
          tabs.value[0],
          1,
          {
            y: '0%',
          },
          0
        )
        .addLabel('tab-1', 0.5)
        .to(
          tabs.value[0],
          1,
          {
            scale: '0.9',
            y: '0',
          },
          0.5
        )
        .to(
          tabs.value[1],
          1,
          {
            y: '0%',
          },
          0.5
        )
        .addLabel('tab-2', 2)
        .to(
          tabs.value[1],
          1,
          {
            scale: '0.9',
            y: '0',
          },
          2
        )
        .to(
          tabs.value[2],
          1,
          {
            y: '0%',
          },
          2
        )
        .addLabel('tab-3', 3)
        .to(
          tabs.value[2],
          1,
          {
            y: '0',
          },
          3
        )
        .to(
          tabs.value[3],
          1,
          {
            y: '0%',
          },
          3
        )
    },
  })
})

onBeforeUnmount(() => {
  tlTabs.kill()
})
</script>

<style lang="scss">
.tabs {
  z-index: 2;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  background-color: $black;
  overflow: hidden;

  .tabs-container {
    width: 95%;
    height: 200vh;
    max-width: 1360px;
    margin: auto;

    @media only screen and (max-height: 699px) {
      height: auto !important;
    }

    @include tablet-landscape(1) {
      height: auto !important;
    }
  }

  .filter {
    padding: 80px 0 40px;

    @media only screen and (max-height: 699px) {
      display: none;
      padding: 0 0 40px;
    }

    @include tablet-landscape(1) {
      display: none;
      padding: 0 0 40px;
    }
  }

  .tabs-wrapper {
    position: relative;

    .tab-content {
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: center;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: calc(100vh - 250px);
      padding: 80px;
      border-radius: 16px;
      background: rgba(44, 44, 44, 1);

      @media only screen and (max-height: 800px) {
        padding: 40px;
      }

      @include tablet-landscape {
        padding: 40px;
      }

      @media only screen and (max-height: 699px) {
        position: relative;
        height: auto;
        transform: none !important;
        margin: 0 0 40px;

        &:last-child {
          margin: 0;
        }
      }

      @include tablet-landscape(1) {
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        height: auto;
        transform: none !important;
        margin: 0 0 40px;

        &:last-child {
          margin: 0;
        }
      }

      &:not(:first-child) {
        transform: translateY(calc(100% + 150px));
      }

      .tab-copy {
        flex: 1;
        max-width: 548px;
        color: $white;

        .headline {
          margin: 0 0 24px;

          @media only screen and (max-height: 800px) {
            font-size: 5rem;
          }
        }

        .text {
          @media only screen and (max-height: 800px) {
            font-size: 1.6rem;
          }
        }
      }

      img,
      video {
        width: 50%;
        max-width: 572px;
        max-height: 100%;
        margin-left: 6%;
        object-fit: contain;

        @include tablet-landscape {
          width: 45%;
        }

        @include tablet-landscape {
          width: auto;
          max-width: 100%;
          margin: 40px 0 0;
        }
      }
    }
  }
}

.multi-page-callout + .tabs {
  padding-top: 0;
}
</style>
