<template>
  <section class="news">
    <div class="eyebrow-container">
      <div class="eyebrow">In The News</div>
    </div>
    <div class="container">
      <div
        v-if="categories.length"
        class="filter post-filter">
        <div class="filter-wrapper">
          <div
            class="category"
            :class="{ active: !filter }"
            @click="handleFilter()">
            <p>All</p>
          </div>
          <template
            v-for="(category, index) in categories"
            :key="index">
            <div
              v-if="category.count"
              class="category"
              :class="{ active: category.id === filter }"
              @click="handleFilter(category.id, category.name)">
              <p v-html="category.name"></p>
            </div>
          </template>
        </div>
      </div>
      <div
        class="filter-dropdown"
        :class="{ open: isOpen }"
        data-lenis-prevent>
        <div
          class="filter-btn"
          :class="{ open: isOpen }"
          @click="open">
          <div class="name">
            {{ activeFilter }}
          </div>
          <img
            class="arrow"
            src="@/assets/img/circle-button-down-ns.png"
            alt="Drawer Arrow" />
        </div>
        <Vue3SlideUpDown v-model="isOpen">
          <div class="filter-wrapper">
            <div
              class="category"
              :class="{ active: !filter }"
              @click="handleFilter()">
              <p>All</p>
            </div>
            <template
              v-for="(category, index) in categories"
              :key="index">
              <div
                v-if="category.count"
                class="category"
                :class="{ active: category.id === filter }"
                @click="handleFilter(category.id, category.name)">
                <p v-html="category.name"></p>
              </div>
            </template>
          </div>
        </Vue3SlideUpDown>
      </div>
    </div>
    <div class="container">
      <div class="posts">
        <template v-if="filteredPosts">
          <PostItem
            v-for="post in filteredPosts"
            :key="post.id"
            :post="post" />
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "NewsComponent",
    "CLASSNAME": "news",
    "URL": "http://local.delphiatx.com/"
   }
  #ENDMETA */
import PostItem from '@/components/template-parts/PostItem.vue'
import { useStore } from '@/stores/main'
import { ref, computed, onBeforeUnmount, onMounted } from 'vue'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

const store = useStore()

defineProps(['data'])

const loading = ref(true)
const filter = ref(false)
const activeFilter = ref('All')
const filteredPosts = ref('')
// const totalPages = ref(0)
const filterExpanded = ref(false)
// const filterOption = ref('All')
const isOpen = ref(false)

const postRequest = {
  type: 'posts',
  params: {
    per_page: 100,
    _fields:
      'id,slug,title,featured_media,link,author,categories,excerpt,date,modified,content,acf',
  },
  showLoading: false,
}

const categoriesRequest = {
  type: 'categories',
  params: {
    orderby: 'id',
  },
}

const open = () => {
  isOpen.value = !isOpen.value
}

const posts = computed(() => {
  return store.requestedItems(postRequest)
})

const categories = computed(() => {
  return store.requestedItems(categoriesRequest)
})

const getPosts = () => {
  store.getItems(postRequest).then(() => {
    filteredPosts.value = posts.value
  })
}

const getCategories = () => {
  store.getItems(categoriesRequest)
}

const handleFilter = (category, name) => {
  if (category) {
    filter.value = category
    const filterRequest = {
      type: 'posts',
      params: {
        per_page: 100,
        categories: category,
        _fields:
          'id,slug,title,featured_media,link,categories,excerpt,date,modified,content,acf',
      },
      showLoading: false,
    }
    store.getItems(filterRequest).then(() => {
      filteredPosts.value = store.requestedItems(filterRequest)
    })
    activeFilter.value = name
    loading.value = false
  } else {
    filter.value = ''
    activeFilter.value = 'All'
    filteredPosts.value = posts.value
  }
  isOpen.value = false
  filterExpanded.value = false
}

onMounted(() => {
  getPosts()
  getCategories()
})

onBeforeUnmount(() => {})
</script>

<style lang="scss">
.news {
  padding: 115px 0 90px;

  @include mobile {
    padding: 50px 0;
  }

  .eyebrow-container {
    padding: 0 5%;
    margin: 0 0 40px;
  }

  .post-filter {
    margin: 0 0 40px;

    @include tablet-landscape(1) {
      display: none;
    }
  }

  .filter-dropdown {
    margin: 0 0 30px;

    @include tablet-landscape(1) {
      display: block;
    }
  }
}
</style>
