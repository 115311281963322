<template>
  <section
    ref="trigger"
    class="approach-callout">
    <div class="ac-wrapper">
      <div class="approach-callout-columns">
        <div class="bg-color"></div>
        <div class="bg-color"></div>
        <div class="bg-color"></div>
      </div>
      <div class="ac-semi-container">
        <div
          ref="semi"
          class="ac-semi"></div>
      </div>
      <div class="approach-callout-labels">
        <p class="h3 font-med">{{ data.approach_callout_col1_text }}</p>
        <p class="h3 font-med">{{ data.approach_callout_col2_text }}</p>
        <p class="h3 font-med">{{ data.approach_callout_col3_text }}</p>
      </div>
      <div class="approach-content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="43"
          viewBox="0 0 12 43"
          fill="none">
          <path
            d="M6 43L6 11"
            stroke="url(#paint0_linear_789_1094)" />
          <circle
            cx="6"
            cy="6"
            r="5.5"
            stroke="#FC9024" />
          <defs>
            <linearGradient
              id="paint0_linear_789_1094"
              x1="2.14748e+09"
              y1="10.4839"
              x2="2.14748e+09"
              y2="42.4839"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#FC9024" />
              <stop
                offset="1"
                stop-color="#EF3A62"
                stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <div
          class="tagline"
          v-html="data.approach_callout_tagline"></div>
        <div
          class="headline h2"
          v-html="data.approach_callout_headline"></div>
        <a
          class="btn"
          :href="data.approach_callout_link.url"
          :target="data.approach_callout_link.target"
          >{{ data.approach_callout_link.title }}</a
        >
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "ApproachCalloutComponent",
    "CLASSNAME": "approach-callout",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { gsap, Linear } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { ref, onMounted, onBeforeUnmount } from 'vue'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const trigger = ref(null)
const semi = ref(null)

let tl

onMounted(() => {
  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top bottom',
      end: '+=80%',
      scrub: true,
    },
  })

  tl.to('.bg-color', {
    yPercent: 0,
    ease: Linear.easeNone,
  }).to(
    semi.value,
    {
      scale: 1,
      ease: Linear.easeNone,
    },
    0
  )
})

onBeforeUnmount(() => {
  tl.kill()
})
</script>

<style lang="scss">
.approach-callout {
  padding: 0 0 150px;
  overflow: hidden;

  @include mobile {
    padding: 0 0 60px;
  }

  .ac-wrapper {
    position: relative;
  }

  .approach-callout-columns {
    position: relative;
    display: flex;
    width: 100%;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.01);
      backdrop-filter: blur(45px);
    }

    .bg-color {
      width: 33.33%;
      height: 55vw;
      max-height: 678px;
      z-index: -1;
      transform: translateY(-50%);

      &:first-child {
        background: linear-gradient(
          180deg,
          #5340e2 -13.2%,
          rgba(83, 64, 226, 0) 67.85%
        );
      }

      &:nth-child(2) {
        background: linear-gradient(
          180deg,
          #64bdff -13.2%,
          rgba(239, 58, 98, 0) 67.85%
        );
      }

      &:last-child {
        background: linear-gradient(
          180deg,
          #ef3a62 -13.2%,
          rgba(239, 58, 98, 0) 67.85%
        );
      }
    }
  }

  .ac-semi {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('@/assets/img/approach-semi-circle.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    transform: scale(0.8);
    transform-origin: top;
  }

  .approach-callout-labels {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 95%;
    max-width: 1300px;
    height: 57vw;
    max-height: 725px;
    margin: auto;

    p {
      position: absolute;
      bottom: 0;

      @include mobile {
        font-size: 1.6rem;
      }

      &:first-child,
      &:last-child {
        bottom: 25%;
      }

      &:nth-child(2) {
        left: 50%;
        transform: translateX(-50%);
      }

      &:last-child {
        right: 0;
      }
    }
  }

  .approach-content {
    position: absolute;
    top: 10%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    text-align: center;
    transform: translateX(-50%);

    @include tablet-landscape {
      top: 8%;
    }

    @include tablet-landscape(1) {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      margin: 50px 0 0;
    }

    svg {
      margin: 0 0 5px;
    }

    .tagline {
      font-size: 18px;
      line-height: 1;
      letter-spacing: 1.44px;
      text-transform: uppercase;
      margin: 0 0 40px;
      color: $orange;
      @include font-main-med;
    }

    .headline {
      max-width: 648px;
      margin: 0 auto 40px;
      @include font-main;
    }
  }
}
</style>
