<template>
  <p
    :class="color"
    v-html="title"></p>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue'
import { useStore } from '../../stores/main'

const store = useStore()
const props = defineProps({
  archiveType: {
    type: String,
    required: true,
  },
  archiveId: {
    type: [Object, Number],
    required: true,
  },
})

const request = computed(() => {
  return {
    type: props.archiveType,
    id: props.archiveType === 'users' ? props.archiveId.id : props.archiveId,
    batch: true,
  }
})

const item = computed(() => {
  return store.singleById(request.value)
})

const title = computed(() => {
  return item.value ? item.value.name : ''
})

const color = computed(() => {
  return item.value ? item.value.acf.category_color.value : ''
})

const getArchiveItem = () => store.getSingleById(request.value)

onBeforeMount(() => getArchiveItem())
</script>

<style lang="scss">
.post-category {
  &.blue {
    color: $blue;
  }

  &.orange {
    color: $orange;
  }

  &.magenta {
    color: $magenta;
  }
}
</style>
