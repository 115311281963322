<template>
  <div class="wrapper">
    <main class="main">
      <template v-if="page">
        <template v-if="page.template === 'page-legal.php'">
          <div class="page-legal-wrapper">
            <div class="container editor-formatting">
              <h1>{{ page.title.rendered }}</h1>
              <div
                class="legal-content"
                v-html="page.content.rendered"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-for="(module, index) in page.acf.modules">
            <template v-if="module.acf_fc_layout === 'HomeHeroComponent'">
              <HomeHeroComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'HeroComponent'">
              <HeroComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'ApproachCalloutComponent'">
              <ApproachCalloutComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'PageCalloutComponent'">
              <PageCalloutComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'MultiPageCalloutComponent'">
              <MultiPageCalloutComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'FeaturedNewsComponent'">
              <FeaturedNewsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'MissionCalloutComponent'">
              <MissionCalloutComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'TeamComponent'">
              <TeamComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'InvestorsComponent'">
              <InvestorsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'NewsComponent'">
              <NewsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'PipelineComponent'">
              <PipelineComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'MissionComponent'">
              <MissionComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'AnchorComponent'">
              <AnchorComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'CareersComponent'">
              <CareersComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'ContactFormComponent'">
              <ContactFormComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'MissionCarouselComponent'">
              <MissionCarouselComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="
                module.acf_fc_layout === 'FullBleedImageTextComponent'
              ">
              <FullBleedImageTextComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'ContactHeroComponent'">
              <ContactHeroComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'TimelineComponent'">
              <TimelineComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'ListComponent'">
              <ListComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'BenefitsComponent'">
              <BenefitsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template
              v-else-if="module.acf_fc_layout === 'AccordionsComponent'">
              <AccordionsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'TabsComponent'">
              <TabsComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
            <template v-else-if="module.acf_fc_layout === 'ApproachComponent'">
              <ApproachComponent
                :key="index"
                :data="module"
                :index="index" />
            </template>
          </template>
        </template>
      </template>
    </main>
  </div>
</template>

<script setup>
import { computed, inject, onBeforeMount, onMounted, nextTick } from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import useUtils from '@/components/composables/useUtils.js'

import HomeHeroComponent from '@/components/modules/HomeHeroComponent.vue'
import HeroComponent from '@/components/modules/HeroComponent.vue'
import ApproachCalloutComponent from '@/components/modules/ApproachCalloutComponent.vue'
import PageCalloutComponent from '@/components/modules/PageCalloutComponent.vue'
import MultiPageCalloutComponent from '@/components/modules/MultiPageCalloutComponent.vue'
import FeaturedNewsComponent from '@/components/modules/FeaturedNewsComponent.vue'
import MissionCalloutComponent from '@/components/modules/MissionCalloutComponent.vue'
import TeamComponent from '@/components/modules/Team/TeamComponent.vue'
import InvestorsComponent from '@/components/modules/InvestorsComponent.vue'
import NewsComponent from '@/components/modules/NewsComponent.vue'
import PipelineComponent from '@/components/modules/Pipeline/PipelineComponent.vue'
import MissionComponent from '@/components/modules/MissionComponent.vue'
import AnchorComponent from '@/components/modules/AnchorComponent.vue'
import CareersComponent from '@/components/modules/CareersComponent.vue'
import ContactFormComponent from '@/components/modules/ContactFormComponent.vue'
import MissionCarouselComponent from '@/components/modules/MissionCarouselComponent.vue'
import FullBleedImageTextComponent from '@/components/modules/FullBleedImageTextComponent.vue'
import ContactHeroComponent from '@/components/modules/ContactHeroComponent.vue'
import TimelineComponent from '@/components/modules/TimelineComponent.vue'
import ListComponent from '@/components/modules/ListComponent.vue'
import BenefitsComponent from '@/components/modules/BenefitsComponent.vue'
import AccordionsComponent from '@/components/modules/Accordions/AccordionsComponent.vue'
import TabsComponent from '@/components/modules/TabsComponent.vue'
import ApproachComponent from '@/components/modules/ApproachComponent.vue'

const store = useStore()
const router = useRouter()
const props = defineProps(['slug', 'isHomepage'])
const request = {
  type: 'pages',
  slug: props.slug,
  showLoading: true,
}

const { decodeTitle } = useUtils()

const navState = inject('navState')

const page = computed(() => {
  return store.getCurrentSingleBySlug(request)
})

onBeforeMount(() => getPage())

onMounted(() => {
  const anchor = router.currentRoute.hash
  nextTick(() => {
    if (anchor && document.querySelector(anchor)) {
      location.href = anchor
    }
  })
})

const getPage = async () => {
  navState.value.class = ''
  store.getSingleBySlug(request).then(() => {
    if (page.value) {
      if (!props.isHomepage) {
        store.updateDocTitle({
          parts: [decodeTitle(page.value.title.rendered), store.site.name],
        })
      } else {
        navState.value.class = ''
        store.updateDocTitle({
          parts: [null, store.site.name],
        })
      }
    } else {
      router.replace('/404')
    }
  })
}
</script>

<style lang="scss">
body {
  overflow-x: hidden;
}
.page-legal-wrapper {
  padding: 130px 0;

  ul {
    @apply ml-[30px] flex flex-col gap-[0px];
    li {
      @apply pl-[30px] relative;
      &:before {
        @apply absolute left-0 top-[11px] content-[''] rounded-full h-[9px] w-[9px];
        box-shadow:
          0px 0px 11.6px $yellow,
          0px 0px 62.8px $yellow;
        background-color: $yellow;
      }
    }
  }

  table {
    @apply w-full;
    tr {
      td {
        @apply w-1/2
          text-[22px];
      }
    }
  }
}
</style>
