<template>
  <section class="mission-callout module">
    <div class="mc-rows">
      <div class="mc-row top">
        <Imager
          :image="data.mission_callout_img_1"
          :left="true"
          class="img-1" />
        <Imager
          :image="data.mission_callout_img_2"
          :right="true"
          class="img-2" />
      </div>
      <div class="mc-row bottom">
        <Imager
          :image="data.mission_callout_img_3"
          :left="true"
          class="img-3" />
        <Imager
          :image="data.mission_callout_img_4"
          :right="true"
          class="img-4" />
      </div>
    </div>
    <div class="lines">
      <div ref="lottie"></div>
    </div>
    <div
      ref="trigger"
      class="content-box">
      <div
        class="eyebrow"
        v-html="data.mission_callout_tagline"></div>
      <div
        class="headline"
        v-html="data.mission_callout_headline"></div>
      <a
        class="btn"
        :href="data.mission_callout_link.url"
        :target="data.mission_callout_link.target">
        {{ data.mission_callout_link.title }}
      </a>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "MissionCalloutComponent",
    "CLASSNAME": "mission-callout",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { ref, onMounted, onBeforeUnmount } from 'vue'
import Imager from '@/components/utility/Imager.vue'
import LottieWeb from 'lottie-web'
import lineJson from '@/assets/lottie/line-homepage.json'
import { create } from '@lottiefiles/lottie-interactivity'
import { gsap, Linear } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const trigger = ref(null)
const lottie = ref(null)

let tl

onMounted(() => {
  const anim = LottieWeb.loadAnimation({
    container: lottie.value,
    renderer: 'svg',
    autoplay: false,
    animationData: lineJson,
  })

  create({
    mode: 'scroll',
    player: anim,
    container: trigger.value,
    actions: [
      {
        visibility: [0, 1],
        type: 'seek',
        frames: [13, 148],
      },
    ],
  })

  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
    },
  })

  tl.to('.img-1', { top: '130px', ease: Linear.easeNone })
    .to(
      '.img-2',
      {
        scale: 1,
        ease: Linear.easeNone,
      },
      0
    )
    .to(
      '.img-3',
      {
        scale: 0.8,
        ease: Linear.easeNone,
      },
      0
    )
    .to(
      '.img-4',
      {
        top: '-130px',
        ease: Linear.easeNone,
      },
      0
    )
})

onBeforeUnmount(() => {
  tl.kill()
  LottieWeb.destroy
})
</script>

<style lang="scss">
.mission-callout {
  overflow: hidden;

  @include mobile {
    padding: 0 !important;
  }

  .mc-row {
    display: flex;
    justify-content: space-between;
    width: 90%;

    @include mobile {
      width: 100%;
    }

    &.top {
      align-items: flex-end;
      margin: 0 0 100px auto;
    }

    &.bottom {
      align-items: flex-start;
    }

    .imager {
      object-fit: contain;

      &.img-1,
      &.img-3 {
        width: 40%;

        @include mobile {
          width: 100%;
        }
      }

      &.img-2,
      &.img-4 {
        width: 60%;
      }

      &.img-1,
      &.img-3 {
        top: 0;
      }

      &.img-1 {
      }

      &.img-2 {
        transform: scale(0.8);
        margin: 0 0 35px;

        @include mobile {
          display: none;
        }
      }

      &.img-3 {
        transform: scale(1);
        margin: 150px 0 0;
      }

      &.img-4 {
        @include mobile {
          display: none;
        }
      }
    }
  }

  .lines {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: -1;
    pointer-events: none;

    @include mobile {
      z-index: 1;
      width: 150%;
    }

    > div {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: contain;
    }
  }

  .content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 700px;
    padding: 120px 80px 60px;
    margin: auto;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    transform: translate(-50%, -50%);

    @include tablet-landscape(1) {
      padding: 100px 60px 60px;
    }

    @include mobile {
      padding: 24px 16px;
      z-index: 2;
    }

    .eyebrow {
      margin: 0 0 40px;

      @include mobile {
        margin: 0 0 24px;
      }
    }

    .btn {
      align-self: flex-start;
      margin: 40px 0 0;

      @include mobile {
        align-self: center;
        margin: 24px 0 0;
      }
    }
  }
}
</style>
