<template>
  <section class="contact-hero">
    <div class="container">
      <div class="contact-hero-container">
        <div class="location loc-1">
          <div
            class="address editor-formatting"
            v-html="site.footer_locations[0].footer_location"></div>
          <div class="location-name">
            {{ site.footer_locations[0].footer_location_name }}
          </div>
          <time>{{
            site.footer_locations[0].footer_location_name === 'PST'
              ? timeCAL
              : timeNY
          }}</time>
        </div>
        <div class="map">
          <img
            src="@/assets/img/contact-map.svg"
            alt="Location Map" />
        </div>
        <div class="location loc-2">
          <div
            class="address editor-formatting"
            v-html="site.footer_locations[1].footer_location"></div>
          <div class="location-name">
            {{ site.footer_locations[1].footer_location_name }}
          </div>
          <time>{{
            site.footer_locations[0].footer_location_name === 'EST'
              ? timeCAL
              : timeNY
          }}</time>
        </div>
      </div>
      <div class="phone-btn">
        <a :href="`mailto:${data.contact_hero_number}`">
          <p class="font-smaller">Main Phone</p>
          <p class="font-larger">{{ data.contact_hero_number }}</p>
        </a>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "GeneralContentComponent",
    "CLASSNAME": "contact-hero",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { ref, onMounted } from 'vue'
import { useStore } from '@/stores/main'
import { formatInTimeZone } from 'date-fns-tz'

defineProps(['data'])

const store = useStore()
const site = ref(store.acfOptions())
const timeNY = ref()
const timeCAL = ref()

onMounted(() => {
  setInterval(() => {
    let date = new Date()
    timeNY.value = formatInTimeZone(date, 'America/New_York', 'h:mm a')
    timeCAL.value = formatInTimeZone(date, 'America/Los_Angeles', 'h:mm a')
  }, 1000)
})
</script>

<style lang="scss">
.contact-hero {
  padding: 200px 0;

  @include mobile {
    padding-bottom: 50px;
  }

  .contact-hero-container {
    display: flex;

    @include tablet-landscape(1) {
      flex-direction: column;
    }

    .map {
      flex: 1;

      @include tablet-landscape(1) {
        order: 0;
        width: 100%;
        margin: 0 0 70px;
      }

      img {
        margin: auto;
      }
    }

    .location {
      @include tablet-landscape(1) {
        order: 1;
        width: 90%;
        max-width: 250px;
        margin: 0 auto 50px;

        &.loc-1 {
          .address:before {
            background: $orange;
            box-shadow:
              0px 0px 12px $orange,
              0px 0px 28px $orange;
          }
        }

        &.loc-2 {
          .address:before {
            background: $blue;
            box-shadow:
              0px 0px 12px $blue,
              0px 0px 28px $blue;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      time {
        display: block;
      }

      .location-name {
        display: inline-block;
        line-height: 1;
        padding: 1px 11px;
        margin: 0 0 10px;
        font-size: 16px;
        border-radius: 17px;
        letter-spacing: 0.48px;
        color: $dark-purple;
        border: 1px solid $dark-purple;
        @include font-main-med;
      }

      .address {
        position: relative;
        margin: 0 0 30px;

        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: -21px;
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          display: none;

          @include tablet-landscape(1) {
            display: block;
          }
        }
      }
    }
  }

  .phone-btn {
    display: inline-block;
    padding: 24px 32px;
    border-radius: 20px;
    margin: 80px 0 0;
    color: $white;
    background: $indigo;

    @include mobile {
      width: 100%;
    }

    .font-smaller {
      margin: 0 0 10px;
    }
  }
}
</style>
