<template>
  <section
    id="leadership"
    class="team-module module white">
    <div class="team-head">
      <div class="container">
        <div
          class="headline h1"
          v-html="data.team_headline"></div>
        <div class="text-filter">
          <div
            class="text editor-formatting"
            v-html="data.team_text"></div>
          <div class="filter">
            <div class="filter-wrapper">
              <div
                v-for="(cat, index) in data.team_category"
                :key="index"
                class="category"
                :class="{ active: index == activeTeam }"
                @click="updateFilter(index)">
                <p>{{ cat.team_category_name }}</p>
              </div>
            </div>
          </div>
          <div
            class="filter-dropdown"
            :class="{ open: isOpen }"
            data-lenis-prevent>
            <div
              class="filter-btn"
              :class="{ open: isOpen }"
              @click="open">
              <div class="name">
                {{ activeFilter }}
              </div>
              <img
                class="arrow"
                src="@/assets/img/circle-button-down-ns.png"
                alt="Drawer Arrow" />
            </div>
            <Vue3SlideUpDown v-model="isOpen">
              <div class="filter-wrapper">
                <div
                  v-for="(cat, index) in data.team_category"
                  :key="index"
                  class="category"
                  :class="{ active: index == activeTeam }"
                  @click="updateFilter(index, cat.team_category_name)">
                  <p>{{ cat.team_category_name }}</p>
                </div>
              </div>
            </Vue3SlideUpDown>
          </div>
        </div>
      </div>
    </div>
    <div class="teams">
      <div class="container">
        <template
          v-for="(category, index) in data.team_category"
          :key="index">
          <Team
            v-if="index == activeTeam"
            :data="category" />
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TeamComponent",
    "CLASSNAME": "team-module",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import Team from '@/components/modules/Team/Team.vue'
import { ref } from 'vue'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

const props = defineProps(['data'])

const activeTeam = ref(0)
const activeFilter = ref(props.data.team_category[0].team_category_name)
const isOpen = ref(false)

const updateFilter = (idx, name) => {
  activeTeam.value = idx
  activeFilter.value = name
  isOpen.value = false
}

const open = () => {
  isOpen.value = !isOpen.value
}
</script>

<style lang="scss">
.team-module {
  .teams {
    .container {
      max-width: 1275px;
    }
  }

  .team-head {
    margin: 0 0 24px;

    @include mobile {
      margin: 0 0 50px;
    }

    .headline {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -28px;
        left: -38px;
        width: 34px;
        height: 34px;
        background-image: url('@/assets/img/icon-eyebrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .text-filter {
      margin: 45px 0 0;

      .filter {
        justify-content: flex-end;

        @include tablet-landscape {
          margin: 10px 0 0;
        }
      }
    }

    .text {
      flex: 1;
      max-width: 515px;
      margin-right: 10%;

      @include mobile {
        margin: 0 0 40px;
      }
    }
  }
}
</style>
