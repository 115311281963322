<template>
  <div class="footer-nav-item-container">
    <nav>
      <ul class="nav-container">
        <template v-for="item in menu">
          <li
            v-if="item.parent === '0'"
            :key="item.id"
            :title="item.title"
            :class="item.class">
            <router-link
              :to="convertToRelative(item.url)"
              :target="item.target"
              v-html="item.content">
            </router-link>
            <ul
              v-if="false"
              class="sub-menu">
              <template v-for="sub in menu">
                <li
                  v-if="sub.parent == item.id"
                  :key="sub.id"
                  :title="sub.title"
                  :class="item.class">
                  <router-link
                    :to="convertToRelative(sub.url)"
                    exact
                    :target="item.target"
                    v-html="sub.content">
                  </router-link>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/components/composables/useUtils.js'

const store = useStore()
const props = defineProps(['name'])
const menu = ref(store.menu({ name: props.name }))
const { convertToRelative } = useUtils()
</script>

<style lang="scss"></style>
