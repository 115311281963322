<template>
  <div class="site-loading-wrap">
    <div class="site-loading">
      <div ref="lottie"></div>
    </div>
  </div>
</template>

<script setup>
import LottieWeb from 'lottie-web'
import logoJson from '@/assets/lottie/logo.json'
import { ref, onMounted, onBeforeUnmount } from 'vue'

const lottie = ref(null)

onMounted(() => {
  setTimeout(() => {
    LottieWeb.loadAnimation({
      container: lottie.value,
      renderer: 'svg',
      autoplay: true,
      loop: true,
      animationData: logoJson,
    })
  }, 1000)
})

onBeforeUnmount(() => {
  LottieWeb.destroy
})
</script>

<style lang="scss">
.site-loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $black;

  .site-loading {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 200px;
    }
  }
}
</style>
