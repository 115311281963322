<template>
  <footer
    v-show="!store.site.loading && site"
    ref="trigger"
    class="site-footer">
    <div class="container large">
      <div class="footer-head">
        <div class="logo">
          <router-link
            :to="'/'"
            title="Home">
            <img
              ref="logo"
              src="@/assets/img/logo-eikon-footer.svg"
              alt="Eikontx Logo" />
          </router-link>
        </div>
        <div class="footer-nav">
          <FooterMenu
            name="footer-menu"
            class="h5" />
        </div>
      </div>
      <div class="footer-locations">
        <div class="locations-wrapper">
          <div
            v-for="(location, index) in site.footer_locations"
            :key="index"
            class="location">
            <div class="location-name">{{ location.footer_location_name }}</div>
            <div
              class="address editor-formatting"
              v-html="location.footer_location"></div>
          </div>
        </div>
      </div>
      <div class="footer-info">
        <div class="socials">
          <a
            v-if="site.social_linkedin"
            :href="site.social_linkedin"
            target="_blank"
            class="social linkedin">
            <img
              src="@/assets/img/logo-linkedin.svg"
              alt="LinkedIn Logo" />
          </a>
          <a
            v-if="site.social_twitter"
            :href="site.social_twitter"
            target="_blank"
            class="social twitter">
            <img
              src="@/assets/img/logo-x.svg"
              alt="X Logo" />
          </a>
        </div>
        <div class="legal-copyright">
          <div class="copyright">
            <p>
              &copy; {{ currentYear }} Eikon Therapeutics, Inc. All rights
              reserved |
            </p>
          </div>
          <footer-menu
            name="legal-menu"
            class="legal-menu" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import { ref, computed, watch } from 'vue'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const site = ref(store.acfOptions())

const loading = computed(() => {
  return store.site.loading
})

const currentYear = computed(() => {
  return new Date().getFullYear()
})

watch(loading, () => {
  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 500)
})
</script>

<style lang="scss">
.site-footer {
  position: relative;
  padding: 50px 0 40px;
  background: url('@/assets/img/bg-footer.png') lightgray 50% / cover no-repeat;
  z-index: 1;
  color: $white;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('@/assets/img/footer-logomark.svg');
    background-repeat: no-repeat;
    background-position: 12% top;

    @include mobile {
      display: none;
    }
  }

  .footer-head {
    display: flex;
    justify-content: space-between;
    margin: 0 0 270px;

    @include mobile {
      flex-direction: column;
      margin: 0 0 56px;
    }

    .logo {
      width: 300px;

      @include mobile {
        order: 2;
      }

      a {
        display: block;

        img {
          width: 100%;
        }
      }
    }

    .footer-nav {
      width: 45%;
      max-width: 450px;

      @include mobile {
        width: 100%;
        max-width: 100%;
        margin: 0 0 100px;
      }

      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
          width: 45%;

          a {
            line-height: 1.5;
            color: $white;
          }
        }
      }
    }
  }

  .footer-locations {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 70px;

    @include mobile {
      margin: 0 0 56px;
    }

    .locations-wrapper {
      display: flex;
      margin-right: 4%;

      @include mobile {
        width: 100%;
        margin-right: 0;
      }
    }

    .location {
      display: flex;
      flex-direction: column;
      margin-right: 60px;

      @include mobile {
        flex: 1;
        margin-right: 30px;
      }

      &:last-child {
        margin: 0;
      }

      .location-name {
        align-self: flex-start;
        font-size: 1.4rem;
        line-height: 1;
        text-transform: uppercase;
        padding: 0 9px;
        margin: 0 0 20px;
        border-radius: 17px;
        border: 1px solid $dark-gray;
        background: $light-gray;
        color: $dark-purple;
      }

      .address {
        font-size: 1.6rem;
      }
    }
  }

  .footer-info {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }

    .socials {
      display: flex;
      align-items: center;

      .social {
        width: 30px;
        margin-right: 10px;

        &:last-child {
          margin: 0;
        }
      }
    }

    .legal-copyright {
      display: flex;
      align-items: center;

      @include mobile {
        width: 100%;
        margin: 20px 0 0;
      }

      .copyright {
        font-size: 1.6rem;
        margin-right: 5px;
      }

      nav {
        ul {
          li {
            margin-right: 10px;

            &:last-child {
              margin: 0;
            }

            a {
              display: block;
              font-size: 1.6rem;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
</style>
