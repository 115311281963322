<template>
  <div
    ref="intro"
    class="intro">
    <div class="video-container">
      <video
        ref="introVideo"
        muted
        playsinline
        preload="auto">
        <source
          :src="site.intro_video.link"
          type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script setup>
import useIntroShown from '@/components/composables/useIntroShown.js'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from '@/stores/main'

const store = useStore()
const site = ref(store.acfOptions())

const { setIntroComplete } = useIntroShown()

const introVideo = ref(null)

const videoFinished = () => {
  document.documentElement.classList.remove('no-overflow')
  setIntroComplete()
}

onMounted(() => {
  introVideo.value.play()
  introVideo.value.addEventListener('ended', videoFinished)
})

onBeforeUnmount(() => {
  introVideo.value.removeEventListener('ended', videoFinished)
})
</script>

<style lang="scss">
.intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $black;
  z-index: 20;

  .video-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    video {
      width: auto;
      max-width: 60%;
      margin: auto;

      @include mobile {
        max-width: 90%;
      }
    }
  }
}
</style>
