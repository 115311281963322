<template>
  <section
    ref="trigger"
    class="page-callout module"
    :class="{ dark: isDark, concise: isConcise, cols: isCols }">
    <div
      ref="pattern"
      class="background-pattern"></div>
    <div class="container">
      <div class="pc-container">
        <div
          class="eyebrow"
          v-html="data.page_callout_tagline"></div>
        <div
          class="headline h1"
          v-html="data.page_callout_headline"></div>
        <div
          v-if="isCols"
          class="pc-cols">
          <div
            class="col editor-formatting"
            v-html="data.page_callout_col1"></div>
          <div
            class="col editor-formatting"
            v-html="data.page_callout_col2"></div>
        </div>
        <div class="text-cta-wrapper">
          <div
            class="text editor-formatting"
            v-html="data.page_callout_text"></div>
          <a
            v-if="data.page_callout_link"
            class="btn"
            :href="data.page_callout_link.url"
            :target="data.page_callout_link.target"
            >{{ data.page_callout_link.title }}</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "PageCalloutComponent",
    "CLASSNAME": "page-callout",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { gsap, Linear } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps(['data'])

const trigger = ref(null)
const pattern = ref(null)

let tl

onMounted(() => {
  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
    },
  })

  tl.to(pattern.value, {
    top: '-40%',
    ease: Linear.easeNone,
  })
})

onBeforeUnmount(() => {
  tl.kill()
})

const isDark = computed(() => {
  return props.data.page_callout_bg_dark.length
})

const isConcise = computed(() => {
  return props.data.page_callout_layout == 'pc_concise'
})

const isCols = computed(() => {
  return props.data.page_callout_layout == 'pc_cols'
})
</script>

<style lang="scss">
.page-callout {
  &.dark {
    color: $white;
    background-color: $black;

    &:before {
      background-image: url('@/assets/img/bg-pc-dark.svg');
    }
  }

  &.concise {
    .container {
      max-width: 970px;
    }

    &:before {
      display: none;
    }

    .pc-container {
      .headline {
        max-width: 100%;
      }

      .text-cta-wrapper {
        margin: 40px 0 0;

        .btn {
          margin: 0;
        }
      }
    }
  }

  &.cols {
    .pc-container {
      .text-cta-wrapper {
        margin: 40px 0 0;

        .btn {
          margin: 0;
        }
      }
    }
  }

  .background-pattern {
    position: absolute;
    top: -55%;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 750px;
    background-image: url('@/assets/img/bg-pc.svg');
    background-repeat: no-repeat;
    background-position: 75% top;
    z-index: -1;
    background-size: contain;
    pointer-events: none;

    @include mobile {
      display: none;
    }
  }

  .pc-container {
    .eyebrow {
      margin: 0 0 40px;
    }

    .headline {
      max-width: 970px;
    }

    .pc-cols {
      display: flex;
      margin: 60px 0 0;

      @include mobile {
        flex-direction: column;
      }

      .col {
        flex: 1;

        &:first-child {
          margin-right: 8%;

          @include mobile {
            margin: 0 0 40px;
          }
        }
      }
    }

    .text-cta-wrapper {
      display: flex;
      flex-direction: column;
      max-width: 550px;
      margin: 90px 10% 0 auto;

      @include mobile {
        margin: 50px 0 0;
      }

      .btn {
        align-self: flex-start;
        margin: 40px 0 0;
      }
    }
  }
}
</style>
