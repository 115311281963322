<template>
  <div class="wrapper">
    <main>
      <article
        v-if="post"
        class="single-post">
        <div class="container">
          <div class="post-head">
            <div class="post-date-source">
              <PostTaxonomies :post="post" />
              <div class="time-source">
                <time>{{ formatDate(post.date) }}</time>
                <span v-if="post.acf.post_source">|</span>
                <div
                  v-if="post.acf.post_source"
                  class="post-source"
                  v-html="post.acf.post_source"></div>
              </div>
            </div>
            <div class="post-title">
              <h1
                class="title"
                v-html="post.title.rendered"></h1>
              <p>{{ formatDate(post.date) }}</p>
            </div>
          </div>
          <div
            v-if="post.featured_image"
            class="featured-image mt-10">
            <img
              :src="post.featured_image.large"
              :alt="post.featured_image.alt" />
          </div>
          <div class="post-content font-larger">
            <template v-if="post.acf.post_external">
              <a :href="post.acf.post_external">Read More</a>
            </template>
            <template v-else>
              <div
                class="editor-formatting"
                v-html="post.content.rendered"></div>
            </template>
          </div>
        </div>
      </article>
    </main>
  </div>
</template>

<script setup>
import { inject, computed, onBeforeMount } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/components/composables/useUtils.js'
import PostTaxonomies from '@/components/utility/PostTaxonomies.vue'

const { decodeTitle } = useUtils()
const navState = inject('navState')
const store = useStore()
const props = defineProps({
  slug: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
  },
})

const request = {
  type: 'posts',
  slug: props.slug,
  showLoading: true,
}

const formatDate = (postDate) => {
  const date = new Date(postDate)

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return new Intl.DateTimeFormat('en-US', dateOptions).format(date)
}

const post = computed(() => {
  return store.singleBySlug(request)
})

const getPost = async () => {
  store.getSingleBySlug(request)
  if (post.value) {
    store.updateDocTitle({
      parts: [decodeTitle(post.value.title.rendered), store.site.name],
    })
  }
}

onBeforeMount(async () => {
  navState.value.class = 'compact'
  getPost()
})
</script>

<style lang="scss">
.single-post {
  padding: 150px 0 100px;

  .post-head {
    padding: 45px;
    border-radius: 14px;
    margin: 0 0 80px;
    border: 1px solid $light-gray;

    @include mobile {
      padding: 30px 20px;
      margin: 0 0 50px;
    }
  }

  .post-date-source {
    display: flex;
    font-size: 18px;
    letter-spacing: 1.44px;
    text-transform: uppercase;
    margin: 0 0 40px;
    @include font-main-med;

    @include mobile {
      flex-direction: column;
    }

    .post-meta {
      margin-right: 150px;
    }

    .time-source {
      display: flex;

      span {
        display: block;
        margin: 0 7px;
      }
    }
  }

  .post-title {
    .title {
      margin: 0 0 20px;
    }
  }
  .post-content {
    .editor-formatting {
      ul {
        @apply ml-[30px] flex flex-col gap-[0px];
        li {
          @apply pl-[30px] relative;
          &:before {
            @apply absolute left-0 top-[11px] content-[''] rounded-full h-[9px] w-[9px];
            box-shadow:
              0px 0px 11.6px $yellow,
              0px 0px 62.8px $yellow;
            background-color: $yellow;
          }
        }
      }

      table {
        @apply w-full;
        tr {
          td {
            @apply w-1/2 border border-solid border-[white]
          text-[22px];
          }
        }
      }
    }
  }
}
</style>
