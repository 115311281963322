<template>
  <section class="multi-page-callout">
    <div class="background">
      <div
        v-if="false"
        ref="shape"
        class="bg-shape"></div>
      <img
        v-lazy="data.multi_page_callout_bg.url"
        :alt="data.multi_page_callout_bg.alt" />
    </div>
    <div class="container">
      <div
        v-if="data.multi_page_callout_intro_tagline"
        class="eyebrow intro-tagline"
        v-html="data.multi_page_callout_intro_tagline"></div>
      <div
        v-if="data.multi_page_callout_intro_headline"
        class="intro-headline h1 font-med"
        v-html="data.multi_page_callout_intro_headline"></div>
      <div class="mpc-container">
        <template v-if="data.multi_page_callout_layout.value === 'ctas'">
          <div
            v-for="(callout, index) in data.multi_page_callout"
            :key="index"
            class="mpc">
            <div class="headline-cta">
              <div
                class="eyebrow"
                v-html="callout.multi_page_callout_tagline"></div>
              <div
                class="headline h3"
                v-html="callout.multi_page_callout_headline"></div>
              <a
                class="btn"
                :href="callout.multi_page_callout_link.url"
                :target="callout.multi_page_callout_link.target"
                >{{ callout.multi_page_callout_link.title }}</a
              >
            </div>
            <div class="text-container">
              <div
                class="text editor-formatting"
                v-html="callout.multi_page_callout_text"></div>
              <a
                class="btn"
                :href="callout.multi_page_callout_link.url"
                :target="callout.multi_page_callout_link.target"
                >{{ callout.multi_page_callout_link.title }}</a
              >
            </div>
          </div>
        </template>
        <template v-else>
          <div class="mpc-cols">
            <div
              v-for="(col, index) in data.multi_page_callout_columns"
              :key="index"
              class="mpc-col-item">
              <div
                v-if="col.multi_page_callout_column_title"
                class="mpc-col-title h3"
                v-html="col.multi_page_callout_column_title"></div>
              <div
                class="mpc-col-text editor-formatting"
                :class="col.multi_page_callout_column_text_size.value"
                v-html="col.multi_page_callout_column_text"></div>
            </div>
          </div>
        </template>
      </div>
      <div
        v-if="data.multi_page_callout_dei"
        class="dei-container">
        <span ref="line"></span>
        <div
          ref="trigger"
          class="dei-wrapper">
          <div
            v-for="(item, index) in data.multi_page_callout_dei"
            :key="index"
            class="dei-item">
            <div class="dot"></div>
            <div class="number">{{ `0${index + 1}` }}</div>
            <div
              class="title font-larger"
              v-html="item.multi_page_callout_dei_title"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "MultiPageCalloutComponent",
    "CLASSNAME": "multi-page-callout",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const trigger = ref(null)
const line = ref(null)

let tl

const destroyTl = () => {
  tl.kill(true)
}

onMounted(() => {
  tl = gsap.timeline({
    onComplete: destroyTl,
    scrollTrigger: {
      trigger: trigger.value,
      start: 'center center',
    },
  })

  tl.to(line.value, {
    height: '100%',
    duration: 1,
  })
})

onBeforeUnmount(() => {
  if (tl) {
    destroyTl()
  }
})
</script>

<style lang="scss">
.multi-page-callout {
  padding: 600px 0 85px;
  background-color: $black;

  @include tablet-landscape {
    padding-top: 500px;
  }

  @include tablet-landscape(1) {
    padding-top: 400px;
    padding-bottom: 60px;
  }

  @include mobile {
    padding-top: 250px;
    padding-bottom: 50px;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    max-height: 1150px;

    @include mobile {
      max-height: 400px;
    }

    .bg-shape {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('@/assets/img/mpc-lines.svg');
      background-repeat: no-repeat;
      background-position: 90% top;
      transform: scale(0.9);
      transform-origin: top;
      opacity: 1;
      visibility: hidden;

      @include mobile {
        display: none;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        #000 15.68%,
        rgba(16, 29, 40, 0.84) 30%,
        rgba(100, 189, 255, 0) 82.61%
      );
      transform: rotate(-180deg);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }

  .intro-tagline {
    margin: 0 0 40px;
  }

  .intro-headline {
    margin: 0 0 100px;
    color: $white;
  }

  .mpc-container {
    position: relative;
    color: $white;

    .mpc {
      display: flex;
      align-items: center;
      margin: 0 0 40px;
      border-radius: 18px;
      border: 1px solid rgba(226, 226, 226, 0.4);

      @include mobile {
        flex-direction: column;
        padding: 20px;
      }

      &:last-child {
        margin: 0;
      }

      .headline-cta {
        display: flex;
        flex-direction: column;
        width: 55%;
        padding: 60px 7%;
        border-right: 1px solid rgba(226, 226, 226, 0.4);

        @include mobile {
          width: 100%;
          padding: 0;
          margin: 0 0 24px;
          border: none;
        }

        .eyebrow {
          margin: 0 0 40px;

          @include mobile {
            margin: 0 0 20px;
          }
        }

        .btn {
          align-self: flex-start;
          margin: 40px 0 0;

          @include mobile {
            display: none;
          }
        }
      }

      .text-container {
        width: 45%;
        padding: 60px 6%;

        @include mobile {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 0;
        }

        .btn {
          display: none;

          @include mobile {
            display: flex;
            align-self: center;
            margin: 24px 0 0;
          }
        }
      }
    }
  }

  .mpc-cols {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mobile {
      flex-direction: column;
    }

    .mpc-col-item {
      width: 46%;
      margin: 0 0 80px;

      @include mobile {
        width: 100%;
        margin: 0 0 40px;

        &:last-child {
          margin: 0;
        }
      }

      &:nth-last-child(n + 2):nth-last-child(-n + 2):first-child,
      &:nth-last-child(n + 2):nth-last-child(-n + 2):first-child ~ a {
        margin: 0;
      }

      .mpc-col-title {
        margin: 0 0 20px;
      }
    }
  }

  .dei-container {
    position: relative;
    padding: 120px 0;

    span {
      display: block;
      position: absolute;
      top: 0;
      left: 15%;
      width: 1px;
      height: 0%;
      background: linear-gradient(
        180deg,
        rgba(239, 58, 98, 0) 0%,
        rgba(239, 58, 98, 1) 20%,
        rgba(100, 189, 255, 1) 40%,
        rgba(83, 64, 226, 1) 60%,
        rgba(252, 144, 36, 1) 80%,
        rgba(83, 64, 226, 0) 100%
      );
    }

    .dei-item {
      position: relative;
      display: flex;
      padding: 0 0 40px;
      margin: 0 0 40px;
      border-bottom: 1px solid rgba(226, 226, 226, 0.4);

      &:first-child {
        .dot {
          background: $cerise;
          box-shadow:
            0px 0px 12px $cerise,
            0px 0px 28px $cerise;
        }

        .number {
          color: $cerise;
        }
      }

      &:nth-child(2) {
        .dot {
          background: $blue;
          box-shadow:
            0px 0px 12px $blue,
            0px 0px 28px $blue;
        }

        .number {
          color: $blue;
        }
      }

      &:nth-child(3) {
        .dot {
          background: $indigo;
          box-shadow:
            0px 0px 12px $indigo,
            0px 0px 28px $indigo;
        }

        .number {
          color: $indigo;
        }
      }

      &:last-child {
        .dot {
          background: $orange;
          box-shadow:
            0px 0px 12px $orange,
            0px 0px 28px $orange;
        }

        .number {
          color: $orange;
        }
      }

      &:last-child {
        padding: 0;
        margin: 0;
        border: none;
      }

      .dot {
        content: '';
        display: block;
        position: absolute;
        top: 12px;
        left: calc(15% + 1px);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        transform: translateX(-50%);
      }

      .number {
        width: 10%;
        margin-right: 10%;
      }

      .title {
        flex: 1;
        color: $white;
      }
    }
  }
}
</style>
