<template>
  <section class="timeline module">
    <div class="container">
      <div class="timeline-head">
        <div
          class="headline h1"
          v-html="data.timeline_headline"></div>
        <div class="cols">
          <div
            class="col edtior-formatting font-smaller"
            v-html="data.timeline_col1_text"></div>
          <div
            class="col edtior-formatting font-smaller"
            v-html="data.timeline_col2_text"></div>
        </div>
      </div>
      <div
        ref="trigger"
        class="timeline-container">
        <div class="lines">
          <div ref="lottie"></div>
        </div>
        <div class="timeline-wrapper">
          <div
            v-for="(item, index) in data.timeline"
            :key="index"
            class="timeline-item">
            <div class="year h1">{{ item.timeline_year }}</div>
            <div
              class="tl-content editor-formatting font-smaller"
              v-html="item.timeline_text"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "TimelineComponent",
    "CLASSNAME": "timeline",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { ref, onMounted, onBeforeUnmount } from 'vue'
import LottieWeb from 'lottie-web'
import lineJson from '@/assets/lottie/line-about.json'
import { create } from '@lottiefiles/lottie-interactivity'

defineProps(['data'])

const trigger = ref(null)
const lottie = ref(null)

onMounted(() => {
  const anim = LottieWeb.loadAnimation({
    container: lottie.value,
    renderer: 'svg',
    autoplay: false,
    animationData: lineJson,
  })

  create({
    mode: 'scroll',
    player: anim,
    container: trigger.value,
    actions: [
      {
        visibility: [0, 1],
        type: 'seek',
        frames: [25, 170],
      },
    ],
  })
})

onBeforeUnmount(() => {
  LottieWeb.destroy
})
</script>

<style lang="scss">
.timeline {
  padding-top: 200px;
  color: $white;
  background-color: $black;

  .lines {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: -1;
    pointer-events: none;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  .timeline-head {
    margin: 0 0 135px;

    .headline {
      position: relative;
      margin: 0 0 55px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -28px;
        left: -38px;
        width: 34px;
        height: 34px;
        background-image: url('@/assets/img/icon-eyebrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .cols {
      display: flex;
      justify-content: space-between;
      margin: 0 0 40px;

      @include mobile {
        flex-direction: column;
      }

      .col {
        width: 48%;
        max-width: 516px;

        @include mobile {
          width: 100%;
          margin: 0 0 30px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  .timeline-container {
    position: relative;

    .timeline-item {
      max-width: 548px;
      margin-bottom: 80px;

      &:nth-child(2n) {
        margin-left: auto;
      }

      .year {
        margin: 0 0 30px;
      }

      .tl-content {
        padding-left: 10%;
      }
    }
  }
}
</style>
