<template>
  <div
    ref="trigger"
    class="pipeline-item"
    :class="{
      open: isOpen,
      'no-info': !item.pipeline_title && !item.pipeline_desc,
    }">
    <div
      class="program-stage"
      @click="open">
      <div class="caret"></div>
      <div class="program font-smaller">
        <div
          class="program-content-wrapper font-larger"
          v-html="item.pipeline_program"></div>
        <div class="program-data-mobile">
          <template v-if="type === 'clinical'">
            <span class="prog-label">{{ item.pipeline_stage_clinical }}</span>
          </template>
          <template v-else>
            <span class="prog-label">{{
              item.pipeline_stage_preclinical
            }}</span>
          </template>
        </div>
      </div>
      <div class="stages">
        <div class="stage"></div>
        <div class="stage"></div>
        <div class="stage"></div>
        <div
          v-if="type === 'clinical'"
          class="stage"></div>
        <div
          ref="stage"
          class="stage-progress"
          :class="item.pipeline_category.value"></div>
      </div>
    </div>
    <Vue3SlideUpDown v-model="isOpen">
      <div class="program-info">
        <div class="program-info-container">
          <div
            class="pipeline-title h3"
            v-html="item.pipeline_title"></div>
          <div class="program-info-label">Summary</div>
          <div
            class="pipeline-desc font-smaller"
            v-html="item.pipeline_desc"></div>
        </div>
      </div>
    </Vue3SlideUpDown>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import { gsap } from 'gsap'

const isOpen = ref(false)

const open = () => {
  isOpen.value = !isOpen.value
}

const props = defineProps(['item', 'type'])

const trigger = ref(null)
const stage = ref(null)

let tl

const destroyTl = () => {
  tl.kill(true)
}

onMounted(() => {
  tl = gsap.timeline({
    onComplete: destroyTl,
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top+=200 bottom',
    },
  })

  tl.to(stage.value, {
    width: `${props.item.pipeline_stage_progress}%`,
    duration: 0.5,
  })
})

onBeforeUnmount(() => {
  if (tl) {
    tl.kill(true)
  }
})
</script>

<style lang="scss">
.pipeline-item {
  position: relative;
  background-color: $white;
  @include transition;

  @include mobile {
    border-bottom: 1px solid $light-gray;
  }

  &.open {
    .program-stage {
      .caret:after {
        background-image: url('@/assets/img/circle-button-up.png');
      }
    }
  }

  &.no-info {
    pointer-events: none;

    .program-stage .caret {
      display: none;
    }
  }

  .program-stage {
    cursor: pointer;
    position: relative;
    display: flex;
    padding-left: 30px;

    @include tablet-landscape(1) {
      padding-left: 15px;
    }

    @include mobile {
      flex-direction: column;
      padding: 11px 20px 25px 20px;
      padding-left: 0;
    }

    .caret {
      position: absolute;
      top: 7px;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: auto;

      @include tablet-landscape(1) {
        width: 30px;
        height: 30px;
      }

      @include mobile {
        width: 40px;
        height: 40px;
        top: 11px;
        bottom: initial;
        right: 15px;
      }

      &:after {
        content: '';
        position: relative;
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('@/assets/img/circle-button-down.png');
        background-repeat: no-repeat;
        background-size: contain;
        transform: scale(1);
        @include transition;
      }
    }

    .program-data-mobile {
      display: none;

      @include mobile {
        display: block;
        font-size: 1.6rem;
        margin-left: 20px;
      }
    }

    .program {
      width: 26.5%;
      padding: 15px 0;

      @include mobile {
        padding: 0px;
        width: 100%;
        margin: 0 0 40px;
      }

      .program-content-wrapper {
        margin-left: 18%;

        @include mobile {
          font-size: 1.6rem;
          margin-left: 20px;
        }

        > * {
          text-decoration: none !important;
          appearance: none !important;
        }
      }
    }

    .stages {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex: 1;

      .stage {
        flex: 1;
        border-left: 1px solid rgba(0, 0, 0, 0.2);
      }

      .stage-progress {
        position: absolute;
        top: 50%;
        left: 0;
        height: 32px;
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        transform: translateY(-50%);

        &.oncology {
          background: linear-gradient(90.02deg, #64bdff 21.34%, #5340e2 65.03%);
        }

        &.immunology {
          background: linear-gradient(84.34deg, #fbff3e -4.42%, #fc9024 80.16%);
        }

        &.neuroscience {
          background: linear-gradient(90deg, #ef3a62 0%, #872b6d 100%);
        }
      }
    }
  }

  .program-info {
    padding: 100px;
    border-bottom: 1px solid $light-gray;

    @include tablet-landscape {
      padding: 50px;
    }

    @include mobile {
      padding: 20px;
    }

    .program-info-label {
      margin: 0 0 8px;
      letter-spacing: 1.44px;
      text-transform: uppercase;
      @include font-main-med;
    }

    .pipeline-title {
      margin: 0 0 24px;
    }
  }
}
</style>
