<template>
  <section class="latest-news">
    <div
      class="ln-overlay"
      @click="closeLatestNews"></div>
    <div class="latest-news-content">
      <div class="logomark">
        <img
          src="@/assets/img/logomark-eikon-news.svg"
          alt="Eikon Logomark" />
        <a
          href="/news"
          class="btn"
          @click="closeLatestNews"
          >View All News</a
        >
      </div>
      <div
        class="latest-news-container"
        data-lenis-prevent>
        <template v-if="loading">
          <div class="news-item-wrapper">
            <div
              v-for="(item, index) in site.global_featured_news_list.length"
              :key="index"
              class="news-item news-placeholder">
              <div
                class="stripe small-stripe"
                style="width: 75px; margin-bottom: 24px"></div>
              <div class="stripe long-stripe"></div>
              <div class="stripe medium-stripe"></div>
              <div
                class="stripe small-stripe"
                style="margin-bottom: 24px"></div>
              <div
                class="stripe small-stripe"
                style="width: 137px"></div>
            </div>
          </div>
        </template>
        <template v-if="posts && posts.length">
          <div
            class="news-item-wrapper"
            data-lenis-prevent>
            <div
              class="close"
              @click="closeLatestNews">
              <img
                src="@/assets/img/button-close.svg"
                alt="Close Button" />
            </div>
            <div
              v-for="post in posts"
              :key="post.id"
              class="news-item">
              <time>{{ formatDate(post.date) }}</time>
              <div
                class="title"
                v-html="post.title.rendered"></div>
              <a
                :href="
                  post.acf.post_external ? post.acf.post_external : post.link
                "
                class="btn pink link"
                :target="post.acf.post_external ? '_blank' : ''"
                @click="closeLatestNews"
                >Read Article</a
              >
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, inject } from 'vue'
import { useStore } from '@/stores/main'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'

defineProps(['data'])

const store = useStore()
const site = ref(store.acfOptions())

const latestNewsOpen = inject('latestNewsOpen')
const { locoScroll } = useSmoothScroll()

const request = {
  type: 'posts',
  params: {
    per_page: 3,
    _fields:
      'id,slug,title,featured_media,link,author,categories,excerpt,date,modified,content,acf',
    include: site.value.global_featured_news_list,
  },
  showLoading: false,
}

const loading = ref(true)

const posts = computed(() => {
  return store.requestedItems(request)
})

const getPosts = () => {
  store.getItems(request).then(() => {
    loading.value = false
  })
}

const closeLatestNews = () => {
  latestNewsOpen.value = false
}

const formatDate = (postDate) => {
  const date = new Date(postDate)

  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }

  return new Intl.DateTimeFormat('en-US', dateOptions).format(date)
}

const closeHandler = (e) => {
  if (e.keyCode === 27) {
    closeLatestNews()
  }
}

onMounted(() => {
  getPosts()
  document.body.classList.add('no-overflow')
  locoScroll.value.stop()
  document.addEventListener('keyup', closeHandler, { passive: true })
})

onBeforeUnmount(() => {
  document.body.classList.remove('no-overflow')
  locoScroll.value.start()
  document.removeEventListener('keyup', closeHandler, { passive: true })
})
</script>

<style lang="scss">
.latest-news {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(30px);

  .ln-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
  }

  .latest-news-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 95%;
    margin: auto;

    @include mobile {
      align-items: flex-end;
    }

    .logomark {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;

      @include tablet-landscape(1) {
        display: none;
      }

      .btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
      }
    }

    .latest-news-container {
      display: flex;
      align-items: center;
      position: relative;
      width: 70%;
      max-width: 550px;
      height: 100%;
      max-height: 85%;

      @include tablet-landscape(1) {
        width: 100%;
        max-width: 100%;
        max-height: 75%;
      }

      @include mobile {
        max-height: 85%;
        margin: 0 0 5%;
      }

      .news-placeholder {
        .small-stripe {
          width: 50%;
        }

        .medium-stripe {
          width: 75%;
        }

        .long-stripe {
          width: 100%;
        }

        .stripe {
          border: 1px solid white;
          height: 22px;
          background-color: #babbbc;
          animation: hintloading 2s ease-in-out 0s infinite reverse;
          -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
        }

        @keyframes hintloading {
          0% {
            opacity: 0.5;
          }

          50% {
            opacity: 1;
          }

          100% {
            opacity: 0.5;
          }
        }
      }

      .news-item-wrapper {
        position: relative;
        width: 100%;
        max-height: 100%;
        max-height: 100%;
        padding: 80px;
        border-radius: 20px;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        background-color: $white;

        @include tablet-landscape {
          padding: 60px;
        }

        @include mobile {
          padding: 40px;
        }
      }

      .close {
        position: absolute;
        top: 8px;
        left: 0;
        z-index: 1;
        cursor: pointer;
      }

      .news-item {
        display: flex;
        flex-direction: column;
        padding: 0 0 40px;
        margin: 0 0 40px;
        border-bottom: 1px solid $light-gray;

        &:last-child {
          padding: 0;
          margin: 0;
          border: none;
        }

        .title {
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin: 24px 0;
        }

        a {
          align-self: flex-start;
        }
      }
    }
  }
}
</style>
