<template>
  <section
    ref="trigger"
    class="hero">
    <div class="hero-bg-container">
      <div
        ref="bg"
        class="hero-bg">
        <template v-if="data.hero_video">
          <video
            loop
            muted
            autoplay
            playsinline
            preload="auto">
            <source
              :src="data.hero_video.url"
              type="video/mp4" />
          </video>
        </template>
        <template v-else>
          <img
            :src="data.hero_image.url"
            :alt="data.hero_image.alt" />
        </template>
      </div>
    </div>
    <div class="container">
      <div
        v-if="data.hero_type.value === 'default'"
        class="hero-content">
        <div
          ref="headline"
          class="headline h2"
          v-html="data.hero_headline"></div>
        <div
          class="text editor-formatting"
          v-html="data.hero_text"></div>
      </div>
      <div
        v-else
        class="hero-news">
        <div
          ref="headline"
          class="headline h2"
          v-html="data.hero_headline"></div>
        <HeroPostItem :post-i-d="data.hero_featured_news[0]" />
      </div>
      <template v-if="data.hero_anchors.length">
        <div class="anchors desktop">
          <div class="eyebrow info">On this page</div>
          <div
            v-for="(item, index) in data.hero_anchors"
            :key="index"
            class="anchor">
            <RouterLink
              :to="convertToRelative(item.hero_anchor.url)"
              exact
              v-html="item.hero_anchor.title">
            </RouterLink>
          </div>
        </div>
      </template>
    </div>
    <div
      ref="iconScroll"
      class="icon-scroll"
      @click="scrollDown">
      <img
        src="@/assets/img/icon-scroll.svg"
        alt="" />
      <div class="line"></div>
    </div>
    <template v-if="data.hero_anchors.length">
      <div
        class="mobile-anchors"
        :class="{ open: isOpen }">
        <div
          class="dropdown-btn font-med"
          @click="open">
          <p>Quick Links</p>
          <div
            class="arrow"
            :class="{ open: isOpen }"></div>
        </div>
        <Vue3SlideUpDown v-model="isOpen">
          <div class="anchors">
            <div
              v-for="(item, index) in data.hero_anchors"
              :key="index"
              class="anchor font-med">
              <RouterLink
                :to="convertToRelative(item.hero_anchor.url)"
                exact
                v-html="item.hero_anchor.title">
              </RouterLink>
            </div>
          </div>
        </Vue3SlideUpDown>
      </div>
    </template>
    <div id="hero-bottom"></div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "HeroComponent",
    "CLASSNAME": "hero",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import { ref, onBeforeUnmount, onMounted } from 'vue'
import useIntroShown from '@/components/composables/useIntroShown.js'
import HeroPostItem from '@/components/template-parts/HeroPostItem.vue'
import useUtils from '@/components/composables/useUtils.js'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import locoScroll from '@/assets/js/LenisScroll.js'
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const { introShown } = useIntroShown()

let tlScroll

const trigger = ref(null)
const bg = ref(null)
const headline = ref(null)
const iconScroll = ref(null)
const isOpen = ref(false)

const tlHeadline = gsap.timeline({
  paused: true,
  delay: 0.5,
})

const open = () => {
  isOpen.value = !isOpen.value
}

const hasIntroShown = () => {
  if (introShown.value) {
    tlHeadline.play()
  } else {
    setTimeout(() => {
      hasIntroShown()
    }, 100)
  }
}

const { convertToRelative } = useUtils()

const headlineTween = () => {
  tlHeadline.staggerTo(
    headline.value.children,
    0.75,
    {
      clipPath: 'polygon(0 0, 100% 0, 100% 110%, 0 110%)',
      y: 0,
      delay: 0.15,
    },
    0.15
  )
}

const scrollDown = () => {
  locoScroll.value.scrollTo('#hero-bottom', {
    immediate: false,
  })
}

onMounted(() => {
  headlineTween()
  hasIntroShown()

  tlScroll = gsap
    .timeline({
      scrollTrigger: {
        trigger: trigger.value,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
      },
    })
    .to(bg.value, {
      yPercent: 80,
      ease: Linear.easeNone,
    })
    .to(
      iconScroll.value,
      {
        autoAlpha: 0,
        ease: Linear.easeNone,
      },
      0
    )
})

onBeforeUnmount(() => {
  tlHeadline.kill()
  tlScroll.kill()
})
</script>

<style lang="scss">
.hero {
  position: relative;
  height: 100vh;
  min-height: 700px;
  padding-top: 250px;
  z-index: 2;

  @include tablet-landscape {
    padding-top: 150px;
  }

  @include tablet-landscape(1) {
    padding-top: 125px;
  }

  @include mobile {
    height: auto;
    min-height: 0;
    padding-bottom: 150px;
  }

  .hero-bg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    max-width: 1196px;

    @include tablet-landscape(1) {
      flex-direction: column;
    }

    .hero-content,
    .anchors {
      border-radius: 20px;
      background: rgba(44, 44, 44, 0.4);
      backdrop-filter: blur(20px);
      color: $white;
    }

    .hero-content {
      align-self: center;
      width: 60%;
      max-width: 676px;
      padding: 40px 32px;

      @include tablet-landscape(1) {
        width: 100%;
      }

      .headline {
        margin: 0 0 40px;

        > * {
          clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
          transform: translateY(100%);
        }
      }
    }

    .anchors {
      align-self: flex-start;
      width: 35%;
      max-width: 312px;
      padding: 40px 40px 40px 45px;

      @include tablet-landscape(1) {
        width: 100%;
        margin: 40px 0 0;
      }

      @include mobile {
        display: none;
      }

      &.mobile {
        display: none;
      }

      .eyebrow {
        margin: 0 0 35px;
      }

      .anchor {
        display: flex;
        margin: 0 0 24px;
        font-size: 1.6rem;
        color: $white;

        &:before {
          position: relative;
          top: 1px;
          content: '';
          display: block;
          width: 17.23px;
          height: 16px;
          margin-right: 8px;
          background-image: url('@/assets/img/dotted-arrow-white.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    .hero-news {
      color: $white;
    }
  }

  .hero-news {
    .headline {
      margin: 0 0 40px;
    }
  }

  .hero-featured-post {
    display: block;
    border-radius: 16px;
    padding: 24px;
    background: rgba(44, 44, 44, 0.4);
    backdrop-filter: blur(20px);

    .post-head {
      display: flex;
      margin: 0 0 40px;

      @include tablet-landscape(1) {
        flex-direction: column;
        border: 1px solid rgba(226, 226, 226, 0.4);
        border-radius: 12px;
      }

      .eyebrow-wrapper {
        position: relative;
        padding: 20px 80px 20px 45px;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border: 1px solid rgba(226, 226, 226, 0.4);

        @include tablet-landscape(1) {
          border: none;
        }

        @include mobile {
          padding: 20px;
          padding-left: 65px;
        }

        .eyebrow {
          padding-top: 0 !important;
          padding-left: 20px;

          @include mobile {
            padding-top: 30px;
            padding-left: 0;
          }

          &:before {
            top: -10px;
          }
        }
      }

      .post-date-source {
        display: flex;
        flex: 1;
        padding: 20px 80px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        border: 1px solid rgba(226, 226, 226, 0.4);
        border-left: none;

        @include tablet-landscape(1) {
          padding: 20px;
          border-radius: 0px;
          border: none;
          border-top: 1px solid rgba(226, 226, 226, 0.4);
        }

        time,
        .post-source {
          display: block;
          font-size: 18px;
          letter-spacing: 1.44px;
          text-transform: uppercase;
          color: $light-gray;
          @include font-main-med;
        }

        span {
          display: block;
          margin: 0 10px;
        }
      }
    }

    .title-btn {
      display: flex;
      flex-direction: column;
      padding: 0 50px 40px;

      @include tablet-landscape(1) {
        padding: 0 30px 30px;
      }

      @include mobile {
        padding: 0 0 30px;
      }

      .title {
        margin: 0 0 40px;
      }

      .btn {
        align-self: flex-start;
      }
    }
  }

  .icon-scroll {
    cursor: pointer;
    position: absolute;
    bottom: 40px;
    right: 40px;
    transform: translateX(-50%);

    @include mobile {
      left: 5%;
      right: initial;
      transform: none;
    }

    &:hover .line {
      top: 30px;
    }

    .line {
      position: absolute;
      top: 14px;
      left: 50%;
      width: 1px;
      height: 12px;
      transform: translateX(-50%);
      background-color: $white;
      @include transition;
    }
  }

  .mobile-anchors {
    display: none;
    position: absolute;
    bottom: 40px;
    right: 5%;
    width: 230px;
    border-radius: 25px;
    background: rgba(44, 44, 44, 0.4);
    backdrop-filter: blur(20px);
    @include transition;

    @include mobile {
      display: flex;
    }

    &.open {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .dropdown-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 1.6rem;
      letter-spacing: 0.48px;
      padding: 10px;
      color: $white;

      .arrow {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: 1px solid $white;
        border-radius: 50%;
        background-color: transparent;
        @include transition;

        &.open {
          background-color: rgba(255, 255, 255, 0.3);

          &:before {
            top: 25%;
            width: 15px;
            height: 15px;
            background-image: url('@/assets/img/dotted-x.svg');
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 20%;
          left: 50%;
          transform: translate(-50%);
          display: block;
          width: 16px;
          height: 18px;
          background-image: url('@/assets/img/dotted-arrow-white.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }

    .anchors {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 10px;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;
      background: rgba(44, 44, 44, 0.4);
      backdrop-filter: blur(20px);

      .anchor {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        margin: 0 0 16px;
        color: $white;

        &:before {
          content: '';
          display: block;
          width: 17.23px;
          height: 16px;
          margin-right: 8px;
          background-image: url('@/assets/img/dotted-arrow-white.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  #hero-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
