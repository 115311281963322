<template>
  <section
    ref="trigger"
    class="home-hero">
    <div
      ref="bg"
      class="hero-bg">
      <template v-if="data.home_hero_video">
        <video
          loop
          muted
          autoplay
          playsinline
          preload="auto">
          <source
            :src="data.home_hero_video.url"
            type="video/mp4" />
        </video>
      </template>
      <template v-else>
        <img
          :src="data.home_hero_image.url"
          :alt="data.home_hero_image.alt" />
      </template>
    </div>
    <div class="container">
      <div
        ref="headlineWrapper"
        class="hero-content">
        <div
          ref="headline"
          class="headline h2"
          v-html="data.home_hero_headline"></div>
      </div>
    </div>
    <div
      v-if="site.global_featured_news_list.length"
      class="news-callout"
      @click="openLatestNews">
      <p>Latest News</p>
      <div class="count">
        <div class="count-inner">
          {{ site.global_featured_news_list.length }}
        </div>
      </div>
    </div>
    <div
      ref="iconScroll"
      class="icon-scroll"
      @click="scrollDown">
      <img
        src="@/assets/img/icon-scroll.svg"
        alt="" />
      <div class="line"></div>
    </div>
    <div id="hero-bottom"></div>
  </section>
</template>

<script setup>
/* #META
   {
    "NAME": "HeroComponent",
    "CLASSNAME": "hero",
    "URL": "http://eikon.local/"
   }
  #ENDMETA */
import locoScroll from '@/assets/js/LenisScroll.js'
import { ref, inject, onBeforeUnmount, onMounted } from 'vue'
import useIntroShown from '@/components/composables/useIntroShown.js'
import { useStore } from '@/stores/main'
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

defineProps(['data'])

const store = useStore()
const site = ref(store.acfOptions())

const { introShown } = useIntroShown()

const headline = ref(null)
const trigger = ref(null)
const bg = ref(null)
const headlineWrapper = ref(null)
const iconScroll = ref(null)

const tlHeadline = gsap.timeline({
  paused: true,
  delay: 0.5,
})

let tlScroll

const hasIntroShown = () => {
  if (introShown.value) {
    tlHeadline.play()
  } else {
    setTimeout(() => {
      hasIntroShown()
    }, 100)
  }
}

const headlineTween = () => {
  tlHeadline.staggerTo(
    headline.value.children,
    0.75,
    {
      clipPath: 'polygon(0 0, 100% 0, 100% 110%, 0 110%)',
      y: 0,
      delay: 0.15,
    },
    0.15
  )
}

const latestNewsOpen = inject('latestNewsOpen')

const openLatestNews = () => {
  latestNewsOpen.value = true
}

const scrollDown = () => {
  locoScroll.value.scrollTo('#hero-bottom', {
    immediate: false,
  })
}

onMounted(() => {
  headlineTween()
  hasIntroShown()

  tlScroll = gsap
    .timeline({
      scrollTrigger: {
        trigger: trigger.value,
        start: 'top top',
        end: 'bottom top',
        scrub: true,
      },
    })
    .to(headlineWrapper.value, {
      yPercent: 200,
      autoAlpha: 0,
      ease: Linear.easeNone,
    })
    .to(
      bg.value,
      {
        yPercent: 80,
        ease: Linear.easeNone,
      },
      0
    )
    .to(
      iconScroll.value,
      {
        autoAlpha: 0,
        ease: Linear.easeNone,
      },
      0
    )
})

onBeforeUnmount(() => {
  tlHeadline.kill()
  tlScroll.kill()
})
</script>

<style lang="scss">
.home-hero {
  position: relative;
  height: 100vh;
  min-height: 800px;
  overflow: hidden;

  @include mobile {
    display: flex;
    align-items: center;
    height: auto;
    min-height: 100vh;
    padding: 100px 0;
  }

  .hero-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        275deg,
        rgba(0, 0, 0, 0) 11.75%,
        rgba(0, 0, 0, 0.5) 79.8%
      );
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }

  .hero-content {
    .headline {
      max-width: 870px;
      color: $white;
      @include font-main;

      > * {
        clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
        transform: translateY(100%);
      }
    }
  }

  .news-callout {
    cursor: pointer;
    position: absolute;
    bottom: 40px;
    right: 30px;
    display: flex;
    align-items: center;
    padding: 11px 8px 11px 20px;
    border-radius: 100px;
    background: var(--light-gray-40, rgba(226, 226, 226, 0.4));
    backdrop-filter: blur(22.350000381469727px);

    p {
      font-size: 1.6rem;
      color: $white;
      @include font-main-med;
    }

    .count {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;

      margin-left: 30px;
      border-radius: 50%;
      background-color: $cerise;
      filter: drop-shadow(0px 0px 12.7px #d45374);

      .count-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.3);
        font-size: 1.4rem;
        color: $white;
        @include font-main-med;
      }
    }
  }

  .icon-scroll {
    cursor: pointer;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);

    @include mobile {
      left: 40px;
    }

    &:hover .line {
      top: 30px;
    }

    .line {
      position: absolute;
      top: 14px;
      left: 50%;
      width: 1px;
      height: 12px;
      transform: translateX(-50%);
      background-color: $white;
      @include transition;
    }
  }

  #hero-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
